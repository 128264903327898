export const userConstants = {

    CHANGE_EMAIL_REQUEST: 'CHANGE_EMAIL_REQUEST',
    CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
    CHANGE_EMAIL_FAILURE: 'CHANGE_EMAIL_FAILURE',

    NOTIFICATION_LIST_REQUEST: 'NOTIFICATION_LIST_REQUEST',
    NOTIFICATION_LIST_SUCCESS: 'NOTIFICATION_LIST_SUCCESS',
    NOTIFICATION_LIST_FAILURE: 'NOTIFICATION_LIST_FAILURE',

    FILE_UPLOAD_CLEAR_EVM_SUCCESS: 'FILE_UPLOAD_CLEAR_EVM_SUCCESS',

    BNB_AMOUNT_FETCH_BY_EVM_TX_REQUEST: 'BNB_AMOUNT_FETCH_BY_EVM_TX_REQUEST',
    BNB_AMOUNT_FETCH_BY_EVM_TX_SUCCESS: 'BNB_AMOUNT_FETCH_BY_EVM_TX_SUCCESS',
    BNB_AMOUNT_FETCH_BY_EVM_TX_FAILURE: 'BNB_AMOUNT_FETCH_BY_EVM_TX_FAILURE',

    EVM_TX_LIST_BY_ADDRESS_REQUEST: 'EVM_TX_LIST_BY_ADDRESS_REQUEST',
    EVM_TX_LIST_BY_ADDRESS_SUCCESS: 'EVM_TX_LIST_BY_ADDRESS_SUCCESS',
    EVM_TX_LIST_BY_ADDRESS_FAILURE: 'EVM_TX_LIST_BY_ADDRESS_FAILURE',

    PASS_PHRASE_VALIDATION_REQUEST: 'PASS_PHRASE_VALIDATION_REQUEST',
    PASS_PHRASE_VALIDATION_SUCCESS: 'PASS_PHRASE_VALIDATION_SUCCESS',
    PASS_PHRASE_VALIDATION_FAILURE: 'PASS_PHRASE_VALIDATION_FAILURE',

    SHOW_MISSING_ALL_COMB_BALANCE_BAR_REQUEST: 'SHOW_MISSING_ALL_COMB_BALANCE_BAR_REQUEST',
    SHOW_MISSING_ALL_COMB_BALANCE_BAR_SUCCESS: 'SHOW_MISSING_ALL_COMB_BALANCE_BAR_SUCCESS',
    SHOW_MISSING_ALL_COMB_BALANCE_BAR_FAILURE: 'SHOW_MISSING_ALL_COMB_BALANCE_BAR_FAILURE',

    BTC_AMOUNT_REQUEST: 'BTC_AMOUNT_REQUEST',
    BTC_AMOUNT_SUCCESS: 'BTC_AMOUNT_SUCCESS',
    BTC_AMOUNT_FAILURE: 'BTC_AMOUNT_FAILURE',

    GET_CUSTOM_IMPORT_LIST_REQUEST: 'GET_CUSTOM_IMPORT_LIST_REQUEST',
    GET_CUSTOM_IMPORT_LIST_SUCCESS: 'GET_CUSTOM_IMPORT_LIST_SUCCESS',
    GET_CUSTOM_IMPORT_LIST_FAILURE: 'GET_CUSTOM_IMPORT_LIST_FAILURE',

    FETCH_PASS_PHRASE_REQUEST: 'FETCH_PASS_PHRASE_REQUEST',
    FETCH_PASS_PHRASE_SUCCESS: 'FETCH_PASS_PHRASE_SUCCESS',
    FETCH_PASS_PHRASE_FAILURE: 'FETCH_PASS_PHRASE_FAILURE',

    CUSTOM_IMPORT_SAVE_REQUEST: 'CUSTOM_IMPORT_SAVE_REQUEST',
    CUSTOM_IMPORT_SAVE_SUCCESS: 'CUSTOM_IMPORT_SAVE_SUCCESS',
    CUSTOM_IMPORT_SAVE_FAILURE: 'CUSTOM_IMPORT_SAVE_FAILURE',

    UPDATE_COIN_RPC_STATUS_REQUEST: 'UPDATE_COIN_RPC_STATUS_REQUEST',
    UPDATE_COIN_RPC_STATUS_SUCCESS: 'UPDATE_COIN_RPC_STATUS_SUCCESS',
    UPDATE_COIN_RPC_STATUS_FAILURE: 'UPDATE_COIN_RPC_STATUS_FAILURE',

    UPDATE_COIN_RPC_REQUEST: 'UPDATE_COIN_RPC_REQUEST',
    UPDATE_COIN_RPC_SUCCESS: 'UPDATE_COIN_RPC_SUCCESS',
    UPDATE_COIN_RPC_FAILURE: 'UPDATE_COIN_RPC_FAILURE',

    CREATE_COIN_RPC_REQUEST: 'CREATE_COIN_RPC_REQUEST',
    CREATE_COIN_RPC_SUCCESS: 'CREATE_COIN_RPC_SUCCESS',
    CREATE_COIN_RPC_FAILURE: 'CREATE_COIN_RPC_FAILURE',

    DELETE_COIN_RPC_REQUEST: 'DELETE_COIN_RPC_REQUEST',
    DELETE_COIN_RPC_SUCCESS: 'DELETE_COIN_RPC_SUCCESS',
    DELETE_COIN_RPC_FAILURE: 'DELETE_COIN_RPC_FAILURE',

    GET_COIN_RPC_LIST_REQUEST: 'GET_COIN_RPC_LIST_REQUEST',
    GET_COIN_RPC_LIST_SUCCESS: 'GET_COIN_RPC_LIST_SUCCESS',
    GET_COIN_RPC_LIST_FAILURE: 'GET_COIN_RPC_LIST_FAILURE',

    SHOW_MISSING_ALL_COMB_BAR_REQUEST: 'SHOW_MISSING_ALL_COMB_BAR_REQUEST',
    SHOW_MISSING_ALL_COMB_BAR_SUCCESS: 'SHOW_MISSING_ALL_COMB_BAR_SUCCESS',
    SHOW_MISSING_ALL_COMB_BAR_FAILURE: 'SHOW_MISSING_ALL_COMB_BAR_FAILURE',

    GET_MISSING_ALL_COMB_BALANCE_BY_ID_REQUEST: 'GET_MISSING_ALL_COMB_BALANCE_BY_ID_REQUEST',
    GET_MISSING_ALL_COMB_BALANCE_BY_ID_SUCCESS: 'GET_MISSING_ALL_COMB_BALANCE_BY_ID_SUCCESS',
    GET_MISSING_ALL_COMB_BALANCE_BY_ID_FAILURE: 'GET_MISSING_ALL_COMB_BALANCE_BY_ID_FAILURE',

    REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_REQUEST: 'REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_REQUEST',
    REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_SUCCESS: 'REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_SUCCESS',
    REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_FAILURE: 'REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_FAILURE',

    GET_MISSING_ALL_COMB_LIST_REQUEST: 'GET_MISSING_ALL_COMB_LIST_REQUEST',
    GET_MISSING_ALL_COMB_LIST_SUCCESS: 'GET_MISSING_ALL_COMB_LIST_SUCCESS',
    GET_MISSING_ALL_COMB_LIST_FAILURE: 'GET_MISSING_ALL_COMB_LIST_FAILURE',

    GET_VALID_BTC_PASS_PHRASES_REQUEST: 'GET_VALID_BTC_PASS_PHRASES_REQUEST',
    GET_VALID_BTC_PASS_PHRASES_SUCCESS: 'GET_VALID_BTC_PASS_PHRASES_SUCCESS',
    GET_VALID_BTC_PASS_PHRASES_FAILURE: 'GET_VALID_BTC_PASS_PHRASES_FAILURE',

    GET_VALID_BNB_PASS_PHRASES_REQUEST: 'GET_VALID_BNB_PASS_PHRASES_REQUEST',
    GET_VALID_BNB_PASS_PHRASES_SUCCESS: 'GET_VALID_BNB_PASS_PHRASES_SUCCESS',
    GET_VALID_BNB_PASS_PHRASES_FAILURE: 'GET_VALID_BNB_PASS_PHRASES_FAILURE',

    GET_VALID_ETH_PASS_PHRASES_REQUEST: 'GET_VALID_ETH_PASS_PHRASES_REQUEST',
    GET_VALID_ETH_PASS_PHRASES_SUCCESS: 'GET_VALID_ETH_PASS_PHRASES_SUCCESS',
    GET_VALID_ETH_PASS_PHRASES_FAILURE: 'GET_VALID_ETH_PASS_PHRASES_FAILURE',

    GET_VALID_SEG_WIT_PASS_PHRASES_REQUEST: 'GET_VALID_SEG_WIT_PASS_PHRASES_REQUEST',
    GET_VALID_SEG_WIT_PASS_PHRASES_SUCCESS: 'GET_VALID_SEG_WIT_PASS_PHRASES_SUCCESS',
    GET_VALID_SEG_WIT_PASS_PHRASES_FAILURE: 'GET_VALID_SEG_WIT_PASS_PHRASES_FAILURE',

    GET_VALID_LEGACY_PASS_PHRASES_REQUEST: 'GET_VALID_LEGACY_PASS_PHRASES_REQUEST',
    GET_VALID_LEGACY_PASS_PHRASES_SUCCESS: 'GET_VALID_LEGACY_PASS_PHRASES_SUCCESS',
    GET_VALID_LEGACY_PASS_PHRASES_FAILURE: 'GET_VALID_LEGACY_PASS_PHRASES_FAILURE',

    GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_REQUEST: 'GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_REQUEST',
    GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_SUCCESS: 'GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_SUCCESS',
    GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_FAILURE: 'GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_FAILURE',

    GET_VALID_PASS_PHRASES_WITH_ADDRESS_REQUEST: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_REQUEST',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_SUCCESS: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_SUCCESS',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_FAILURE: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_FAILURE',

    GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_REQUEST: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_REQUEST',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_SUCCESS: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_SUCCESS',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_FAILURE: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_FAILURE',

    GET_ADDRESS_BALANCE_BY_ID_REQUEST: 'GET_ADDRESS_BALANCE_BY_ID_REQUEST',
    GET_ADDRESS_BALANCE_BY_ID_SUCCESS: 'GET_ADDRESS_BALANCE_BY_ID_SUCCESS',
    GET_ADDRESS_BALANCE_BY_ID_FAILURE: 'GET_ADDRESS_BALANCE_BY_ID_FAILURE',

    SHOW_PASS_PHRASES_PROGRESS_BAR_REQUEST: 'SHOW_PASS_PHRASES_PROGRESS_BAR_REQUEST',
    SHOW_PASS_PHRASES_PROGRESS_BAR_SUCCESS: 'SHOW_PASS_PHRASES_PROGRESS_BAR_SUCCESS',
    SHOW_PASS_PHRASES_PROGRESS_BAR_FAILURE: 'SHOW_PASS_PHRASES_PROGRESS_BAR_FAILURE',

    SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_REQUEST: 'SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_REQUEST',
    SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_SUCCESS: 'SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_SUCCESS',
    SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_FAILURE: 'SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_FAILURE',

    SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_REQUEST: 'SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_REQUEST',
    SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_SUCCESS: 'SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_SUCCESS',
    SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_FAILURE: 'SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_FAILURE',

    SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_REQUEST: 'SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_REQUEST',
    SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_SUCCESS: 'SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_SUCCESS',
    SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_FAILURE: 'SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_FAILURE',

    GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_REQUEST: 'GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_REQUEST',
    GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_SUCCESS: 'GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_SUCCESS',
    GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_FAILURE: 'GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_FAILURE',

    GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_REQUEST: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_REQUEST',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_SUCCESS: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_SUCCESS',
    GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_FAILURE: 'GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_FAILURE',

    GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_REQUEST: 'GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_REQUEST',
    GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_SUCCESS: 'GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_SUCCESS',
    GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_FAILURE: 'GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_FAILURE',

    GET_TX_LIST_REQUEST: 'GET_TX_LIST_REQUEST',
    GET_TX_LIST_SUCCESS: 'GET_TX_LIST_SUCCESS',
    GET_TX_LIST_FAILURE: 'GET_TX_LIST_FAILURE',

    SAVE_PRIVATE_KEY_REQUEST: 'SAVE_PRIVATE_KEY_REQUEST',
    SAVE_PRIVATE_KEY_SUCCESS: 'SAVE_PRIVATE_KEY_SUCCESS',
    SAVE_PRIVATE_KEY_FAILURE: 'SAVE_PRIVATE_KEY_FAILURE',

    GET_BTC_AMOUNT_BY_ADDRESS_REQUEST: 'GET_BTC_AMOUNT_BY_ADDRESS_REQUEST',
    GET_BTC_AMOUNT_BY_ADDRESS_SUCCESS: 'GET_BTC_AMOUNT_BY_ADDRESS_SUCCESS',
    GET_BTC_AMOUNT_BY_ADDRESS_FAILURE: 'GET_BTC_AMOUNT_BY_ADDRESS_FAILURE',

    RECOVER_EMAIL_VERIFY_OTP_REQUEST: 'RECOVER_EMAIL_VERIFY_OTP_REQUEST',
    RECOVER_EMAIL_VERIFY_OTP_SUCCESS: 'RECOVER_EMAIL_VERIFY_OTP_SUCCESS',
    RECOVER_EMAIL_VERIFY_OTP_FAILURE: 'RECOVER_EMAIL_VERIFY_OTP_FAILURE',

    VERIFY_RECOVER_EMAIL_REQUEST: 'VERIFY_RECOVER_EMAIL_REQUEST',
    VERIFY_RECOVER_EMAIL_SUCCESS: 'VERIFY_RECOVER_EMAIL_SUCCESS',
    VERIFY_RECOVER_EMAIL_FAILURE: 'VERIFY_RECOVER_EMAIL_FAILURE',

    ADD_NOTES_IN_EVM_ACCOUNT_REQUEST: 'ADD_NOTES_IN_EVM_ACCOUNT_REQUEST',
    ADD_NOTES_IN_EVM_ACCOUNT_SUCCESS: 'ADD_NOTES_IN_EVM_ACCOUNT_SUCCESS',
    ADD_NOTES_IN_EVM_ACCOUNT_FAILURE: 'ADD_NOTES_IN_EVM_ACCOUNT_FAILURE',

    SAVE_PASS_PHRASES_EVM_REQUEST: 'SAVE_PASS_PHRASES_EVM_REQUEST',
    SAVE_PASS_PHRASES_EVM_SUCCESS: 'SAVE_PASS_PHRASES_EVM_SUCCESS',
    SAVE_PASS_PHRASES_EVM_FAILURE: 'SAVE_PASS_PHRASES_EVM_FAILURE',

    VALIDATE_EVM_PRIVATE_KEY_REQUEST: 'VALIDATE_EVM_PRIVATE_KEY_REQUEST',
    VALIDATE_EVM_PRIVATE_KEY_SUCCESS: 'VALIDATE_EVM_PRIVATE_KEY_SUCCESS',
    VALIDATE_EVM_PRIVATE_KEY_FAILURE: 'VALIDATE_EVM_PRIVATE_KEY_FAILURE',

    VALIDATE_EVM_PASS_PASS_PHRASES_REQUEST: 'VALIDATE_EVM_PASS_PASS_PHRASES_REQUEST',
    VALIDATE_EVM_PASS_PASS_PHRASES_SUCCESS: 'VALIDATE_EVM_PASS_PASS_PHRASES_SUCCESS',
    VALIDATE_EVM_PASS_PASS_PHRASES_FAILURE: 'VALIDATE_EVM_PASS_PASS_PHRASES_FAILURE',

    BNB_AMOUNT_REQUEST: 'BNB_AMOUNT_REQUEST',
    BNB_AMOUNT_SUCCESS: 'BNB_AMOUNT_SUCCESS',
    BNB_AMOUNT_FAILURE: 'BNB_AMOUNT_FAILURE',

    EVM_TX_LIST_REQUEST: 'EVM_TX_LIST_REQUEST',
    EVM_TX_LIST_SUCCESS: 'EVM_TX_LIST_SUCCESS',
    EVM_TX_LIST_FAILURE: 'EVM_TX_LIST_FAILURE',

    RECOVER_EMAIL_REQUEST: 'RECOVER_EMAIL_REQUEST',
    RECOVER_EMAIL_SUCCESS: 'RECOVER_EMAIL_SUCCESS',
    RECOVER_EMAIL_FAILURE: 'RECOVER_EMAIL_FAILURE',

    VALIDATE_EVM_PRIVATE_KYE_REQUEST: 'VALIDATE_EVM_PRIVATE_KYE_REQUEST',
    VALIDATE_EVM_PRIVATE_KYE_SUCCESS: 'VALIDATE_EVM_PRIVATE_KYE_SUCCESS',
    VALIDATE_EVM_PRIVATE_KYE_FAILURE: 'VALIDATE_EVM_PRIVATE_KYE_FAILURE',

    GET_EVM_ACCOUNT_LIST_REQUEST: 'GET_EVM_ACCOUNT_LIST_REQUEST',
    GET_EVM_ACCOUNT_LIST_SUCCESS: 'GET_EVM_ACCOUNT_LIST_SUCCESS',
    GET_EVM_ACCOUNT_LIST_FAILURE: 'GET_EVM_ACCOUNT_LIST_FAILURE',

    ADD_NOTES_IN_BTC_ACCOUNT_REQUEST: 'ADD_NOTES_IN_BTC_ACCOUNT_REQUEST',
    ADD_NOTES_IN_BTC_ACCOUNT_SUCCESS: 'ADD_NOTES_IN_BTC_ACCOUNT_SUCCESS',
    ADD_NOTES_IN_BTC_ACCOUNT_FAILURE: 'ADD_NOTES_IN_BTC_ACCOUNT_FAILURE',

    VALIDATE_PRIVATE_KYE_REQUEST: 'VALIDATE_PRIVATE_KYE_REQUEST',
    VALIDATE_PRIVATE_KYE_SUCCESS: 'VALIDATE_PRIVATE_KYE_SUCCESS',
    VALIDATE_PRIVATE_KYE_FAILURE: 'VALIDATE_PRIVATE_KYE_FAILURE',

    DELETE_NOTIFICATION_REQUEST: 'DELETE_NOTIFICATION_REQUEST',
    DELETE_NOTIFICATION_SUCCESS: 'DELETE_NOTIFICATION_SUCCESS',
    DELETE_NOTIFICATION_FAILURE: 'DELETE_NOTIFICATION_FAILURE',

    GET_USER_ANSER_LIST_REQUEST: 'GET_USER_ANSER_LIST_REQUEST',
    GET_USER_ANSER_LIST_SUCCESS: 'GET_USER_ANSER_LIST_SUCCESS',
    GET_USER_ANSER_LIST_FAILURE: 'GET_USER_ANSER_LIST_FAILURE',

    GET_QUESTION_LIST_REQUEST: 'GET_QUESTION_LIST_REQUEST',
    GET_QUESTION_LIST_SUCCESS: 'GET_QUESTION_LIST_SUCCESS',
    GET_QUESTION_LIST_FAILURE: 'GET_QUESTION_LIST_FAILURE',

    GET_ALL_COIN_REQUEST: 'GET_ALL_COIN_REQUEST',
    GET_ALL_COIN_SUCCESS: 'GET_ALL_COIN_SUCCESS',
    GET_ALL_COIN_FAILURE: 'GET_ALL_COIN_FAILURE',

    ADD_ANSWERS_REQUEST: 'ADD_ANSWERS_REQUEST',
    ADD_ANSWERS_SUCCESS: 'ADD_ANSWERS_SUCCESS',
    ADD_ANSWERS_FAILURE: 'ADD_ANSWERS_FAILURE',

    VALIDATE_PASS_PHRASES_REQUEST: 'VALIDATE_PASS_PHRASES_REQUEST',
    VALIDATE_PASS_PHRASES_SUCCESS: 'VALIDATE_PASS_PHRASES_SUCCESS',
    VALIDATE_PASS_PHRASES_FAILURE: 'VALIDATE_PASS_PHRASES_FAILURE',

    SAVE_PASS_PHRASES_REQUEST: 'SAVE_PASS_PHRASES_REQUEST',
    SAVE_PASS_PHRASES_SUCCESS: 'SAVE_PASS_PHRASES_SUCCESS',
    SAVE_PASS_PHRASES_FAILURE: 'SAVE_PASS_PHRASES_FAILURE',

    USER_SECURITY_UPDATE_REQUEST: 'USER_SECURITY_UPDATE_REQUEST',
    USER_SECURITY_UPDATE_SUCCESS: 'USER_SECURITY_UPDATE_SUCCESS',
    USER_SECURITY_UPDATE_FAILURE: 'USER_SECURITY_UPDATE_FAILURE',

    GET_M7I_COIN_REQUEST: 'GET_M7I_COIN_REQUEST',
    GET_M7I_COIN_SUCCESS: 'GET_M7I_COIN_SUCCESS',
    GET_M7I_COIN_FAILURE: 'GET_M7I_COIN_FAILURE',

    COIN_SWAP_REQUEST: 'COIN_SWAP_REQUEST',
    COIN_SWAP_SUCCESS: 'COIN_SWAP_SUCCESS',
    COIN_SWAP_FAILURE: 'COIN_SWAP_FAILURE',

    GET_BPT_DETAIS_REQUEST: 'GET_BPT_DETAIS_REQUEST',
    GET_BPT_DETAIS_SUCCESS: 'GET_BPT_DETAIS_SUCCESS',
    GET_BPT_DETAIS_FAILURE: 'GET_BPT_DETAIS_FAILURE',

    GET_ALL_COIN_REMOVE_ID_REQUEST: 'GET_ALL_COIN_REMOVE_ID_REQUEST',
    GET_ALL_COIN_REMOVE_ID_SUCCESS: 'GET_ALL_COIN_REMOVE_ID_SUCCESS',
    GET_ALL_COIN_REMOVE_ID_FAILURE: 'GET_ALL_COIN_REMOVE_ID_FAILURE',

    GET_RECIEVE_COIN_REQUEST: 'GET_RECIEVE_COIN_REQUEST',
    GET_RECIEVE_COIN_SUCCESS: 'GET_RECIEVE_COIN_SUCCESS',
    GET_RECIEVE_COIN_FAILURE: 'GET_RECIEVE_COIN_FAILURE',

    GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST',
    GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS',
    GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE: 'GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE',

    CREATE_USER_PROMO_PACKAGE_REQUEST: 'CREATE_USER_PROMO_PACKAGE_REQUEST',
    CREATE_USER_PROMO_PACKAGE_SUCCESS: 'CREATE_USER_PROMO_PACKAGE_SUCCESS',
    CREATE_USER_PROMO_PACKAGE_FAILURE: 'CREATE_USER_PROMO_PACKAGE_FAILURE',

    GET_ADDRESS_DEPOSIT_TIME_REQUEST: 'GET_ADDRESS_DEPOSIT_TIME_REQUEST',
    GET_ADDRESS_DEPOSIT_TIME_SUCCESS: 'GET_ADDRESS_DEPOSIT_TIME_SUCCESS',
    GET_ADDRESS_DEPOSIT_TIME_FAILURE: 'GET_ADDRESS_DEPOSIT_TIME_FAILURE',

    CREATE_NOTIFICATION_REQUEST: 'CREATE_NOTIFICATION_REQUEST',
    CREATE_NOTIFICATION_SUCCESS: 'CREATE_NOTIFICATION_SUCCESS',
    CREATE_NOTIFICATION_FAILURE: 'CREATE_NOTIFICATION_FAILURE',

    GET_ALL_LEVEL_MINING_REQUEST: 'GET_ALL_LEVEL_MINING_REQUEST',
    GET_ALL_LEVEL_MINING_SUCCESS: 'GET_ALL_LEVEL_MINING_SUCCESS',
    GET_ALL_LEVEL_MINING_FAILURE: 'GET_ALL_LEVEL_MINING_FAILURE',

    GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST: 'GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST',
    GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS: 'GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS',
    GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE: 'GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE',

    REPLY_TICKET_BY_USER__REQUEST: 'REPLY_TICKET_BY_USER__REQUEST',
    REPLY_TICKET_BY_USER__SUCCESS: 'REPLY_TICKET_BY_USER__SUCCESS',
    REPLY_TICKET_BY_USER__FAILURE: 'REPLY_TICKET_BY_USER__FAILURE',

    GET_LEVEL_BY_ID_USER_TX_REQUEST: 'GET_LEVEL_BY_ID_USER_TX_REQUEST',
    GET_LEVEL_BY_ID_USER_TX_SUCCESS: 'GET_LEVEL_BY_ID_USER_TX_SUCCESS',
    GET_LEVEL_BY_ID_USER_TX_FAILURE: 'GET_LEVEL_BY_ID_USER_TX_FAILURE',

    GET_LEVEL_BY_ID_USER_REQUEST: 'GET_LEVEL_BY_ID_USER_REQUEST',
    GET_LEVEL_BY_ID_USER_SUCCESS: 'GET_LEVEL_BY_ID_USER_SUCCESS',
    GET_LEVEL_BY_ID_USER_FAILURE: 'GET_LEVEL_BY_ID_USER_FAILURE',

    GET_ALL_LEVEL_WELCOME_REQUEST: 'GET_ALL_LEVEL_WELCOME_REQUEST',
    GET_ALL_LEVEL_WELCOME_SUCCESS: 'GET_ALL_LEVEL_WELCOME_SUCCESS',
    GET_ALL_LEVEL_WELCOME_FAILURE: 'GET_ALL_LEVEL_WELCOME_FAILURE',


    GET_USER_LIST_BY_EMAIL_REQUEST: 'GET_USER_LIST_BY_EMAIL_REQUEST',
    GET_USER_LIST_BY_EMAIL_SUCCESS: 'GET_USER_LIST_BY_EMAIL_SUCCESS',
    GET_USER_LIST_BY_EMAIL_FAILURE: 'GET_USER_LIST_BY_EMAIL_FAILURE',

    WITHDRAW_BALANCE_REQUEST: 'WITHDRAW_BALANCE_REQUEST',
    WITHDRAW_BALANCE_SUCCESS: 'WITHDRAW_BALANCE_SUCCESS',
    WITHDRAW_BALANCE_FAILURE: 'WITHDRAW_BALANCE_FAILURE',

    GET_COIN_CMC_REQUEST: 'GET_COIN_CMC_REQUEST',
    GET_COIN_CMC_SUCCESS: 'GET_COIN_CMC_SUCCESS',
    GET_COIN_CMC_FAILURE: 'GET_COIN_CMC_FAILURE',

    CLAIM_ADDRESS_REQUEST: 'CLAIM_ADDRESS_REQUEST',
    CLAIM_ADDRESS_SUCCESS: 'CLAIM_ADDRESS_SUCCESS',
    CLAIM_ADDRESS_FAILURE: 'CLAIM_ADDRESS_FAILURE',

    SAVE_DFT_WALLET_ADDRESS_REQUEST: 'SAVE_DFT_WALLET_ADDRESS_REQUEST',
    SAVE_DFT_WALLET_ADDRESS_SUCCESS: 'SAVE_DFT_WALLET_ADDRESS_SUCCESS',
    SAVE_DFT_WALLET_ADDRESS_FAILURE: 'SAVE_DFT_WALLET_ADDRESS_FAILURE',

    GET_DFT_WALLET_REQUEST: 'GET_DFT_WALLET_REQUEST',
    GET_DFT_WALLET_SUCCESS: 'GET_DFT_WALLET_SUCCESS',
    GET_DFT_WALLET_FAILURE: 'GET_DFT_WALLET_FAILURE',

    GET_PACKAGE_LIST_REQUEST: 'GET_PACKAGE_LIST_REQUEST',
    GET_PACKAGE_LIST_SUCCESS: 'GET_PACKAGE_LIST_SUCCESS',
    GET_PACKAGE_LIST_FAILURE: 'GET_PACKAGE_LIST_FAILURE',

    GET_TICKET_BY_ID_LIST_REQUEST: 'GET_TICKET_BY_ID_LIST_REQUEST',
    GET_TICKET_BY_ID_LIST_SUCCESS: 'GET_TICKET_BY_ID_LIST_SUCCESS',
    GET_TICKET_BY_ID_LIST_FAILURE: 'GET_TICKET_BY_ID_LIST_FAILURE',

    GET_ALL_CITY_REQUEST: 'GET_ALL_CITY_REQUEST',
    GET_ALL_CITY_SUCCESS: 'GET_ALL_CITY_SUCCESS',
    GET_ALL_CITY_FAILURE: 'GET_ALL_CITY_FAILURE',

    TX_LIST_BY_DATE_REQUEST: 'TX_LIST_BY_DATE_REQUEST',
    TX_LIST_BY_DATE_SUCCESS: 'TX_LIST_BY_DATE_SUCCESS',
    TX_LIST_BY_DATE_FAILURE: 'TX_LIST_BY_DATE_FAILURE',

    GET_ALL_STATE_REQUEST: 'GET_ALL_STATE_REQUEST',
    GET_ALL_STATE_SUCCESS: 'GET_ALL_STATE_SUCCESS',
    GET_ALL_STATE_FAILURE: 'GET_ALL_STATE_FAILURE',

    GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST',
    GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS',
    GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE: 'GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE',

    GET_INR_COIN_ID_REQUEST: 'GET_INR_COIN_ID_REQUEST',
    GET_INR_COIN_ID_SUCCESS: 'GET_INR_COIN_ID_SUCCESS',
    GET_INR_COIN_ID_FAILURE: 'GET_INR_COIN_ID_FAILURE',

    GET_USER_NAME_VERIFIED_REQUEST: 'GET_USER_NAME_VERIFIED_REQUEST',
    GET_USER_NAME_VERIFIED_SUCCESS: 'GET_USER_NAME_VERIFIED_SUCCESS',
    GET_USER_NAME_VERIFIED_FAILURE: 'GET_USER_NAME_VERIFIED_FAILURE',

    CREATE_MAKE_PAYMENT_REQUEST: 'CREATE_MAKE_PAYMENT_REQUEST',
    CREATE_MAKE_PAYMENT_SUCCESS: 'CREATE_MAKE_PAYMENT_SUCCESS',
    CREATE_MAKE_PAYMENT_FAILURE: 'CREATE_MAKE_PAYMENT_FAILURE',

    GET_ALL_COUNTRY_REQUEST: 'GET_ALL_COUNTRY_REQUEST',
    GET_ALL_COUNTRY_SUCCESS: 'GET_ALL_COUNTRY_SUCCESS',
    GET_ALL_COUNTRY_FAILURE: 'GET_ALL_COUNTRY_FAILURE',

    SELL_INDEX_REQUEST: 'SELL_INDEX_REQUEST',
    SELL_INDEX_SUCCESS: 'SELL_INDEX_SUCCESS',
    SELL_INDEX_FAILURE: 'SELL_INDEX_FAILURE',

    CREATE_WALLET_REQUEST: 'CREATE_WALLET_REQUEST',
    CREATE_WALLET_SUCCESS: 'CREATE_WALLET_SUCCESS',
    CREATE_WALLET_FAILURE: 'CREATE_WALLET_FAILURE',

    GET_INDEX_ORDER_REQUEST: 'GET_INDEX_ORDER_REQUEST',
    GET_INDEX_ORDER_SUCCESS: 'GET_INDEX_ORDER_SUCCESS',
    GET_INDEX_ORDER_FAILURE: 'GET_INDEX_ORDER_FAILURE',

    GET_COIN_BALANCE_REQUEST: 'GET_COIN_BALANCE_REQUEST',
    GET_COIN_BALANCE_SUCCESS: 'GET_COIN_BALANCE_SUCCESS',
    GET_COIN_BALANCE_FAILURE: 'GET_COIN_BALANCE_FAILURE',

    BUY_INDEX_REQUEST: 'BUY_INDEX_REQUEST',
    BUY_INDEX_SUCCESS: 'BUY_INDEX_SUCCESS',
    BUY_INDEX_FAILURE: 'BUY_INDEX_FAILURE',

    GET_ALL_INDEX_REQUEST: 'GET_ALL_INDEX_REQUEST',
    GET_ALL_INDEX_SUCCESS: 'GET_ALL_INDEX_SUCCESS',
    GET_ALL_INDEX_FAILURE: 'GET_ALL_INDEX_FAILURE',

    GET_BTC_ACCOUNT_LIST_REQUEST: 'GET_BTC_ACCOUNT_LIST_REQUEST',
    GET_BTC_ACCOUNT_LIST_SUCCESS: 'GET_BTC_ACCOUNT_LIST_SUCCESS',
    GET_BTC_ACCOUNT_LIST_FAILURE: 'GET_BTC_ACCOUNT_LIST_FAILURE',

    SAVE_KYC_DOC_SELFIE_REQUEST: 'SAVE_KYC_DOC_SELFIE_REQUEST',
    SAVE_KYC_DOC_SELFIE_SUCCESS: 'SAVE_KYC_DOC_SELFIE_SUCCESS',
    SAVE_KYC_DOC_SELFIE_FAILURE: 'SAVE_KYC_DOC_SELFIE_FAILURE',

    SAVE_KYC_DOC_PASSPORT_REQUEST: 'SAVE_KYC_DOC_PASSPORT_REQUEST',
    SAVE_KYC_DOC_PASSPORT_SUCCESS: 'SAVE_KYC_DOC_PASSPORT_SUCCESS',
    SAVE_KYC_DOC_PASSPORT_FAILURE: 'SAVE_KYC_DOC_PASSPORT_FAILURE',

    SAVE_KYC_DOC_PAN_REQUEST: 'SAVE_KYC_DOC_PAN_REQUEST',
    SAVE_KYC_DOC_PAN_SUCCESS: 'SAVE_KYC_DOC_PAN_SUCCESS',
    SAVE_KYC_DOC_PAN_FAILURE: 'SAVE_KYC_DOC_PAN_FAILURE',

    SAVE_KYC_DOC_AADHAR_REQUEST: 'SAVE_KYC_DOC_AADHAR_REQUEST',
    SAVE_KYC_DOC_AADHAR_SUCCESS: 'SAVE_KYC_DOC_AADHAR_SUCCESS',
    SAVE_KYC_DOC_AADHAR_FAILURE: 'SAVE_KYC_DOC_AADHAR_FAILURE',

    SAVE_KYC_DOC_REQUEST: 'SAVE_KYC_DOC_REQUEST',
    SAVE_KYC_DOC_SUCCESS: 'SAVE_KYC_DOC_SUCCESS',
    SAVE_KYC_DOC_FAILURE: 'SAVE_KYC_DOC_FAILURE',

    GET_DOCUMENT_LIST_OBJ_REQUEST: 'GET_DOCUMENT_LIST_OBJ_REQUEST',
    GET_DOCUMENT_LIST_OBJ_SUCCESS: 'GET_DOCUMENT_LIST_OBJ_SUCCESS',
    GET_DOCUMENT_LIST_OBJ_FAILURE: 'GET_DOCUMENT_LIST_OBJ_FAILURE',

    SAVE_USER_INFO_REQUEST: 'SAVE_USER_INFO_REQUEST',
    SAVE_USER_INFO_SUCCESS: 'SAVE_USER_INFO_SUCCESS',
    SAVE_USER_INFO_FAILURE: 'SAVE_USER_INFO_FAILURE',

    SAVE_BTST_REQUEST: 'SAVE_BTST_REQUEST',
    SAVE_BTST_SUCCESS: 'SAVE_BTST_SUCCESS',
    SAVE_BTST_FAILURE: 'SAVE_BTST_FAILURE',

    SAVE_STAKE_REQUEST: 'SAVE_STAKE_REQUEST',
    SAVE_STAKE_SUCCESS: 'SAVE_STAKE_SUCCESS',
    SAVE_STAKE_FAILURE: 'SAVE_STAKE_FAILURE',


    USER_TICKET_CREATE_REQUEST: 'USER_TICKET_CREATE_REQUEST',
    USER_TICKET_CREATE_SUCCESS: 'USER_TICKET_CREATE_SUCCESS',
    USER_TICKET_CREATE_FAILURE: 'USER_TICKET_CREATE_FAILURE',

    UPDATE_PASSWORD_VALIDATE_OTP_REQUEST: 'UPDATE_PASSWORD_VALIDATE_OTP_REQUEST',
    UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS: 'UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS',
    UPDATE_PASSWORD_VALIDATE_OTP_FAILURE: 'UPDATE_PASSWORD_VALIDATE_OTP_FAILURE',

    VALIDATE_LOGIN_OTP_REQUEST: 'VALIDATE_LOGIN_OTP_REQUEST',
    VALIDATE_LOGIN_OTP_SUCCESS: 'VALIDATE_LOGIN_OTP_SUCCESS',
    VALIDATE_LOGIN_OTP_FAILURE: 'VALIDATE_LOGIN_OTP_FAILURE',

    FORGOT_PASS_UPDATE_REQUEST: 'USERS_FORGOT_PASS_UPDATE_REQUEST',
    FORGOT_PASS_UPDATE_SUCCESS: 'USERS_FORGOT_PASS_UPDATE_SUCCESS',
    FORGOT_PASS_UPDATE_FAILURE: 'USERS_FORGOT_PASS_UPDATE_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_BTST_REQUEST: 'USERS_GET_BTST_REQUEST',
    GET_BTST_SUCCESS: 'USERS_GET_BTST_SUCCESS',
    GET_BTST_FAILURE: 'USERS_GET_BTST_FAILURE',

    GET_STAKE_REQUEST: 'USERS_GET_STAKE_REQUEST',
    GET_STAKE_SUCCESS: 'USERS_GET_STAKE_SUCCESS',
    GET_STAKE_FAILURE: 'USERS_GET_STAKE_FAILURE',

    GET_PACKAGE_BTST_REQUEST: 'USERS_GET_PACKAGE_BTST_REQUEST',
    GET_PACKAGE_BTST_SUCCESS: 'USERS_GET_PACKAGE_BTST_SUCCESS',
    GET_PACKAGE_BTST_FAILURE: 'USERS_GET_PACKAGE_BTST_FAILURE',

    GET_PACKAGE_STAKE_REQUEST: 'USERS_GET_PACKAGE_STAKE_REQUEST',
    GET_PACKAGE_STAKE_SUCCESS: 'USERS_GET_PACKAGE_STAKE_SUCCESS',
    GET_PACKAGE_STAKE_FAILURE: 'USERS_GET_PACKAGE_STAKE_FAILURE',

    SIGNUP_REQUEST: 'USERS_SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'USERS_SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'USERS_SIGNUP_FAILURE',

    LOGIN_FIRST_REQUEST: 'USERS_FIRST_LOGIN_REQUEST',
    LOGIN_FIRST_SUCCESS: 'USERS_FIRST_LOGIN_SUCCESS',
    LOGIN_FIRST_FAILURE: 'USERS_FIRST_LOGIN_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',


    SEND_COIN_REQUEST: 'SEND_COIN__REQUEST',
    SEND_COIN_SUCCESS: 'SEND_COIN__SUCCESS',
    SEND_COIN_FAILURE: 'SEND_COIN__FAILURE',

    SEND_BALANCE_REQUEST: 'SEND_BALANCE__REQUEST',
    SEND_BALANCE_SUCCESS: 'SEND_BALANCE__SUCCESS',
    SEND_BALANCE_FAILURE: 'SEND_BALANCE__FAILURE',

    TX_LIST_REQUEST: 'TX_LIST__REQUEST',
    TX_LIST_SUCCESS: 'TX_LIST__SUCCESS',
    TX_LIST_FAILURE: 'TX_LIST__FAILURE',

    TX_LIST_OVERFLOW_REQUEST: 'TX_LIST_OVERFLOW_REQUEST',
    TX_LIST_OVERFLOW_SUCCESS: 'TX_LIST_OVERFLOW_SUCCESS',
    TX_LIST_OVERFLOW_FAILURE: 'TX_LIST_OVERFLOW_FAILURE',

    GET_USER_WALLET_REQUEST: 'GET_USER_WALLET__REQUEST',
    GET_USER_WALLET_SUCCESS: 'GET_USER_WALLET__SUCCESS',
    GET_USER_WALLET_FAILURE: 'GET_USER_WALLET__FAILURE',

    FORGOT_FIRST_REQUEST: 'USERS_FIRST_FORGOT_REQUEST',
    FORGOT_FIRST_SUCCESS: 'USERS_FIRST_FORGOT_SUCCESS',
    FORGOT_FIRST_FAILURE: 'USERS_FIRST_FORGOT_FAILURE',

    SEND_REQUEST: 'USERS_SEND_REQUEST',
    SEND_SUCCESS: 'USERS_SEND_SUCCESS',
    SEND_FAILURE: 'USERS_SEND_FAILURE',

    SEND_OTP_REQUEST: 'USERS_SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS: 'USERS_SEND_OTP_SUCCESS',
    SEND_OTP_FAILURE: 'USERS_SEND_OTP_FAILURE',

    TOKEN_REQUEST: 'USERS_TOKEN_REQUEST',
    TOKEN_SUCCESS: 'USERS_TOKEN_SUCCESS',
    TOKEN_FAILURE: 'USERS_TOKEN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    CMC_COIN_BY_TICKER_NAME_REQUEST: 'CMC_COIN_BY_TICKER_NAME_REQUEST',
    CMC_COIN_BY_TICKER_NAME_SUCCESS: 'CMC_COIN_BY_TICKER_NAME_SUCCESS',
    CMC_COIN_BY_TICKER_NAME_FAILURE: 'CMC_COIN_BY_TICKER_NAME_FAILURE',

    PROFILE_PI_REQUEST: 'PROFILE_PI_REQUEST',
    PROFILE_PI_SUCCESS: 'PROFILE_PI_SUCCESS',
    PROFILE_PI_FAILURE: 'PROFILE_PI_FAILURE',

    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',

    SOCIAL_DETAILS_REQUEST: 'SOCIAL_DETAILS_REQUEST',
    SOCIAL_DETAILS_SUCCESS: 'SOCIAL_DETAILS_SUCCESS',
    SOCIAL_DETAILS_FAILURE: 'SOCIAL_DETAILS_FAILURE',

    UPDATE_USER_DETAILS_REQUEST: 'UPDATE_USER_DETAILS_REQUEST',
    UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
    UPDATE_USER_DETAILS_FAILURE: 'UPDATE_USER_DETAILS_FAILURE',

    PRICE_REQUEST: 'PRICE_REQUEST',
    PRICE_SUCCESS: 'PRICE_SUCCESS',
    PRICE_FAILURE: 'PRICE_FAILURE',

    SWAP_REQUEST: 'SWAP_REQUEST',
    SWAP_SUCCESS: 'SWAP_SUCCESS',
    SWAP_FAILURE: 'SWAP_FAILURE',


    USER_DOC_LIST_REQUEST: 'USER_DOC_LIST_REQUEST',
    USER_DOC_LIST_SUCCESS: 'USER_DOC_LIST_SUCCESS',
    USER_DOC_LIST_FAILURE: 'USER_DOC_LIST_FAILURE',

    GET_KYC_REQUEST: 'GET_KYC_REQUEST',
    GET_KYC_SUCCESS: 'GET_KYC_SUCCESS',
    GET_KYC_FAILURE: 'GET_KYC_FAILURE',

    USER_KYC_CREATE_REQUEST: 'USER_KYC_CREATE_REQUEST',
    USER_KYC_CREATE_SUCCESS: 'USER_KYC_CREATE_SUCCESS',
    USER_KYC_CREATE_FAILURE: 'USER_KYC_CREATE_FAILURE',


    GET_MAKE_PAYMENT_LIST_REQUEST: 'GET_MAKE_PAYMENT_LIST_REQUEST',
    GET_MAKE_PAYMENT_LIST_SUCCESS: 'GET_MAKE_PAYMENT_LIST_SUCCESS',
    GET_MAKE_PAYMENT_LIST_FAILURE: 'GET_MAKE_PAYMENT_LIST_FAILURE',


    ADD_IMAGE_BPT_REQUEST: 'ADD_IMAGE_BPT_REQUEST',
    ADD_IMAGE_BPT_SUCCESS: 'ADD_IMAGE_BPT_SUCCESS',
    ADD_IMAGE_BPT_FAILURE: 'ADD_IMAGE_BPT_FAILURE',

    USER_TICKET_LIST_REQUEST: 'USER_TICKET_LIST_REQUEST',
    USER_TICKET_LIST_SUCCESS: 'USER_TICKET_LIST_SUCCESS',
    USER_TICKET_LIST_FAILURE: 'USER_TICKET_LIST_FAILURE',

    GETALL_USER_NOTIFY_REQUEST: 'GETALL_USER_NOTIFY_REQUEST',
    GETALL_USER_NOTIFY_SUCCESS: 'GETALL_USER_NOTIFY_SUCCESS',
    GETALL_USER_NOTIFY_FAILURE: 'GETALL_USER_NOTIFY_FAILURE',

    UPDATE_USER_NOTIFY_REQUEST: 'UPDATE_USER_NOTIFY_REQUEST',
    UPDATE_USER_NOTIFY_SUCCESS: 'UPDATE_USER_NOTIFY_SUCCESS',
    UPDATE_USER_NOTIFY_FAILURE: 'UPDATE_USER_NOTIFY_FAILURE',


    ADD_RESTAURANT_USER_REQUEST: 'ADD_RESTAURANT_USER_REQUEST',
    ADD_RESTAURANT_USER_SUCCESS: 'ADD_RESTAURANT_USER_SUCCESS',
    ADD_RESTAURANT_USER_FAILURE: 'ADD_RESTAURANT_USER_FAILURE',

    FILE_UPLOAD_SELFIE_STATUS_REQUEST: 'FILE_UPLOAD_SELFIE_STATUS_REQUEST',
    FILE_UPLOAD_SELFIE_STATUS_SUCCESS: 'FILE_UPLOAD_SELFIE_STATUS_SUCCESS',
    FILE_UPLOAD_SELFIE_CLEAR_SUCCESS: 'FILE_UPLOAD_SELFIE_CLEAR_SUCCESS',
    FILE_UPLOAD_SELFIE_STATUS_FAILURE: 'FILE_UPLOAD_SELFIE_STATUS_FAILURE',

    FILE_UPLOAD_STATUS_PAN_REQUEST: 'FILE_UPLOAD_STATUS_PAN_REQUEST',
    FILE_UPLOAD_STATUS_PAN_SUCCESS: 'FILE_UPLOAD_STATUS_PAN_SUCCESS',
    FILE_UPLOAD_STATUS_PAN_FAILURE: 'FILE_UPLOAD_STATUS_PAN_FAILURE',

    FILE_UPLOAD_STATUS_ADHAAR_REQUEST: 'FILE_UPLOAD_STATUS_ADHAAR_REQUEST',
    FILE_UPLOAD_STATUS_ADHAAR_SUCCESS: 'FILE_UPLOAD_STATUS_ADHAAR_SUCCESS',
    FILE_UPLOAD_STATUS_ADHAAR_FAILURE: 'FILE_UPLOAD_STATUS_ADHAAR_FAILURE',

    FILE_UPLOAD_STATUS_ADHAAR_BACK_REQUEST: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_REQUEST',
    FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS',
    FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE: 'FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE',

    FILE_UPLOAD_STATUS_PASSPORT_REQUEST: 'FILE_UPLOAD_STATUS_PASSPORT_REQUEST',
    FILE_UPLOAD_STATUS_PASSPORT_SUCCESS: 'FILE_UPLOAD_STATUS_PASSPORT_SUCCESS',
    FILE_UPLOAD_STATUS_PASSPORT_FAILURE: 'FILE_UPLOAD_STATUS_PASSPORT_FAILURE',

    FILE_UPLOAD_CLEAR_PAN_SUCCESS: 'FILE_UPLOAD_CLEAR_PAN_SUCCESS',
    FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS: 'FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS',
    FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS: 'FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS',

    FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS: 'FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_CLEAR_SUCCESS: 'FILE_UPLOAD_CLEAR_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',


    DATA_CLEAR_SUCCESS: 'DATA_CLEAR_SUCCESS',

    DATA_CLEAR_EVM_SUCCESS: 'DATA_CLEAR_EVM_SUCCESS',

    RESET_USER_REDUX: 'RESET_USER_REDUX',


    GET_RESTAURANT_REQUEST: 'GET_RESTAURANT_REQUEST',
    GET_RESTAURANT_SUCCESS: 'GET_RESTAURANT_SUCCESS',
    GET_RESTAURANT_FAILURE: 'GET_RESTAURANT_FAILURE',

    ADD_MENU_REQUEST: 'ADD_MENU_REQUEST',
    ADD_MENU_SUCCESS: 'ADD_MENU_SUCCESS',
    ADD_MENU_FAILURE: 'ADD_MENU_FAILURE',


    ADD_ITEM_REQUEST: 'ADD_ITEM_REQUEST',
    ADD_ITEM_SUCCESS: 'ADD_ITEM_SUCCESS',
    ADD_ITEM_FAILURE: 'ADD_ITEM_FAILURE',

    DEL_ITEM_REQUEST: 'DEL_ITEM_REQUEST',
    DEL_ITEM_SUCCESS: 'DEL_ITEM_SUCCESS',
    DEL_ITEM_FAILURE: 'DEL_ITEM_FAILURE',

    UPDATE_CATEGORY_REQUEST: 'UPDATE_CATEGORY_REQUEST',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILURE: 'UPDATE_CATEGORY_FAILURE',

    UPDATE_ITEM_REQUEST: 'UPDATE_ITEM_REQUEST',
    UPDATE_ITEM_SUCCESS: 'UPDATE_ITEM_SUCCESS',
    UPDATE_ITEM_FAILURE: 'UPDATE_ITEM_FAILURE',


    STATS_REQUEST: 'STATS_REQUEST',
    STATS_SUCCESS: 'STATS_SUCCESS',
    STATS_FAILURE: 'STATS_FAILURE',


    SWAP_COIN_AMOUNT_REQUEST: 'SWAP_COIN_AMOUNT_REQUEST',
    SWAP_COIN_AMOUNT_SUCCESS: 'SWAP_COIN_AMOUNT_SUCCESS',
    SWAP_COIN_AMOUNT_FAILURE: 'SWAP_COIN_AMOUNT_FAILURE',


    GET_USER_WALLET_BY_COIN_NAME_REQUEST: 'GET_USER_WALLET_BY_COIN_NAME_REQUEST',
    GET_USER_WALLET_BY_COIN_NAME_SUCCESS: 'GET_USER_WALLET_BY_COIN_NAME_SUCCESS',
    GET_USER_WALLET_BY_COIN_NAME_FAILURE: 'GET_USER_WALLET_BY_COIN_NAME_FAILURE',


    REGISTER_FIRST_REQUEST: 'USERS_FIRST_REGISTER_REQUEST',
    REGISTER_FIRST_SUCCESS: 'USERS_FIRST_REGISTER_SUCCESS',
    REGISTER_FIRST_FAILURE: 'USERS_FIRST_REGISTER_FAILURE',

    COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
    COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
    COUNTRY_LIST_FAILURE: 'COUNTRY_LIST_FAILURE',

    CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
    CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
    CITY_LIST_FAILURE: 'CITY_LIST_FAILURE',

    STATES_LIST_REQUEST: 'STATES_LIST_REQUEST',
    STATES_LIST_SUCCESS: 'STATES_LIST_SUCCESS',
    STATES_LIST_FAILURE: 'STATES_LIST_FAILURE',

    GET_REFERAL_BY_ID_REQUEST: 'GET_REFERAL_BY_ID_REQUEST',
    GET_REFERAL_BY_ID_SUCCESS: 'GET_REFERAL_BY_ID_SUCCESS',
    GET_REFERAL_BY_ID_FAILURE: 'GET_REFERAL_BY_ID_FAILURE',

    GET_REFERAL_COUNT_AND_SUM_REQUEST: 'GET_REFERAL_COUNT_AND_SUM_REQUEST',
    GET_REFERAL_COUNT_AND_SUM_SUCCESS: 'GET_REFERAL_COUNT_AND_SUM_SUCCESS',
    GET_REFERAL_COUNT_AND_SUM_FAILURE: 'GET_REFERAL_COUNT_AND_SUM_FAILURE',

    SAVE_USER_PERSONAL_ADDR_REQUEST: 'SAVE_USER_PERSONAL_ADDR_REQUEST',
    SAVE_USER_PERSONAL_ADDR_SUCCESS: 'SAVE_USER_PERSONAL_ADDR_SUCCESS',
    SAVE_USER_PERSONAL_ADDR_FAILURE: 'SAVE_USER_PERSONAL_ADDR_FAILURE',

    SAVE_SOCIAL_MEDIA_REQUEST: 'SAVE_SOCIAL_MEDIA_REQUEST',
    SAVE_SOCIAL_MEDIA_SUCCESS: 'SAVE_SOCIAL_MEDIA_SUCCESS',
    SAVE_SOCIAL_MEDIA_FAILURE: 'SAVE_SOCIAL_MEDIA_FAILURE',


    GET_USER_BY_REFCODE_REQUEST: 'GET_USER_BY_REFCODE_REQUEST',
    GET_USER_BY_REFCODE_SUCCESS: 'GET_USER_BY_REFCODE_SUCCESS',
    GET_USER_BY_REFCODE_FAILURE: 'GET_USER_BY_REFCODE_FAILURE',

    //New
    GET_USER_BY_NEW_REQUEST: 'GET_USER_BY_NEW_REQUEST',
    GET_USER_BY_NEW_SUCCESS: 'GET_USER_BY_NEW_SUCCESS',
    GET_USER_BY_NEW_FAILURE: 'GET_USER_BY_NEW_FAILURE',

    // new 
    GET_MESSAGE_CLIENT_LIST_REQUEST: 'MESSAGE_CLIENT_LIST_REQUEST',
    GET_MESSAGE_CLIENT_LIST_SUCCESS: 'MESSAGE_CLIENT_LIST_SUCCESS',
    GET_MESSAGE_CLIENT_LIST_FAILURE: 'MESSAGE_CLIENT_LIST_FAILURE:',

    //New 
    GET_COIN_BY_NAME_REQUEST: 'GET_COIN_BY_NAME_REQUEST',
    GET_COIN_BY_NAME_SUCCESS: 'GET_COIN_BY_NAME_SUCCESS',
    GET_COIN_BY_NAME_FAILURE: 'GET_COIN_BY_NAME_FAILURE',

    //New 
    GET_ADDRESS_DETAILS_BY_ID_REQUEST: 'GET_ADDRESS_DETAILS_BY_ID_REQUEST',
    GET_ADDRESS_DETAILS_BY_ID_SUCCESS: 'GET_ADDRESS_DETAILS_BY_ID_SUCCESS',
    GET_ADDRESS_DETAILS_BY_ID_FAILURE: 'GET_ADDRESS_DETAILS_BY_ID_FAILURE',




    //new for Two missingPass 
    DELETE_TWOMISSINGPASS_REQUEST: 'DELETE_TWOMISSINGPASS_REQUEST',
    DELETE_TWOMISSINGPASS_SUCCESS: 'DELETE_TWOMISSINGPASS_SUCCESS',
    DELETE_TWOMISSINGPASS_FAILURE: 'DELETE_TWOMISSINGPASS_FAILURE',


    //new for Two missingPass 
    DELETE_MAIN_PASSPHRASE_COMB_BALANCE_REQUEST: 'DELETE_MAIN_PASSPHRASE_COMB_BALANCE_REQUEST',
    DELETE_MAIN_PASSPHRASE_COMB_BALANCE_SUCCESS: 'DELETE_MAIN_PASSPHRASE_COMB_BALANCE_SUCCESS',
    DELETE_MAIN_PASSPHRASE_COMB_BALANCE_FAILURE: 'DELETE_MAIN_PASSPHRASE_COMB_BALANCE_FAILURE',

    //new for Two missingPass 
    DELETE_MAIN_PASSPHRASE_COMB_REQUEST: 'DELETE_MAIN_PASSPHRASE_COMB_REQUEST',
    DELETE_MAIN_PASSPHRASE_COMB_SUCCESS: 'DELETE_MAIN_PASSPHRASE_COMB_SUCCESS',
    DELETE_MAIN_PASSPHRASE_COMB_FAILURE: 'DELETE_MAIN_PASSPHRASE_COMB_FAILURE',

    //new for Two missingPass 
    DELETE_MAIN_PASSPHRASE_REQUEST: 'DELETE_MAIN_PASSPHRASE_REQUEST',
    DELETE_MAIN_PASSPHRASE_SUCCESS: 'DELETE_MAIN_PASSPHRASE_SUCCESS',
    DELETE_MAIN_PASSPHRASE_FAILURE: 'DELETE_MAIN_PASSPHRASE_FAILURE',

    //new for Two missingPass 
    PASSPHASE_ALLCOMB_MULTIPLE_DELETE_REQUEST: 'PASSPHASE_ALLCOMB_MULTIPLE_DELETE_REQUEST',
    PASSPHASE_ALLCOMB_MULTIPLE_DELETE_SUCCESS: 'PASSPHASE_ALLCOMB_MULTIPLE_DELETE_SUCCESS',
    PASSPHASE_ALLCOMB_MULTIPLE_DELETE_FAILURE: 'PASSPHASE_ALLCOMB_MULTIPLE_DELETE_FAILURE',


    //new for Two missingPass 
    VALID_PASS_PHRASE_DELETE_REQUEST: 'VALID_PASS_PHRASE_DELETE_REQUEST',
    VALID_PASS_PHRASE_DELETE_SUCCESS: 'VALID_PASS_PHRASE_DELETE_SUCCESS',
    VALID_PASS_PHRASE_DELETE_FAILURE: 'VALID_PASS_PHRASE_DELETE_FAILURE',

    GET_MAIN_PASS_PHRASE_REQUEST: 'GET_MAIN_PASS_PHRASE_REQUEST',
    GET_MAIN_PASS_PHRASE_SUCCESS: 'GET_MAIN_PASS_PHRASE_SUCCESS',
    GET_MAIN_PASS_PHRASE_FAILURE: 'GET_MAIN_PASS_PHRASE_FAILURE',


    GET_MAIN_PASS_PHRASE_COMB_REQUEST: 'GET_MAIN_PASS_PHRASE_COMB_REQUEST',
    GET_MAIN_PASS_PHRASE_COMB_SUCCESS: 'GET_MAIN_PASS_PHRASE_COMB_SUCCESS',
    GET_MAIN_PASS_PHRASE_COMB_FAILURE: 'GET_MAIN_PASS_PHRASE_COMB_FAILURE',


    GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_REQUEST: 'GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_REQUEST',
    GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_SUCCESS: 'GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_SUCCESS',
    GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_FAILURE: 'GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_FAILURE',

    SAVE_MAIN_PASS_PHRASE_REQUEST: 'SAVE_MAIN_PASS_PHRASE_REQUEST',
    SAVE_MAIN_PASS_PHRASE_SUCCESS: 'SAVE_MAIN_PASS_PHRASE_SUCCESS',
    SAVE_MAIN_PASS_PHRASE_FAILURE: 'SAVE_MAIN_PASS_PHRASE_FAILURE',

    GET_ONE_PASS_COMBO_LIST_REQUEST: 'GET_ONE_PASS_COMBO_LIST_REQUEST',
    GET_ONE_PASS_COMBO_LIST_SUCCESS: 'GET_ONE_PASS_COMBO_LIST_SUCCESS',
    GET_ONE_PASS_COMBO_LIST_FAILURE: 'GET_ONE_PASS_COMBO_LIST_FAILURE',

    SHOW_ONE_PASS_COMBO_BAR_REQUEST: 'SHOW_ONE_PASS_COMBO_BAR_REQUEST',
    SHOW_ONE_PASS_COMBO_BAR_SUCCESS: 'SHOW_ONE_PASS_COMBO_BAR_SUCCESS',
    SHOW_ONE_PASS_COMBO_BAR_FAILURE: 'SHOW_ONE_PASS_COMBO_BAR_FAILURE',

};
