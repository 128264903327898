import React, { Component } from "react";
import { userActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Papa from "papaparse";
import moment from "moment";
import { MdOutlineContentCopy } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
const bip39 = require('bip39')

class CustomImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      // popupList: [],
      errorsUser: {},
      fieldsUser: {},
      fieldsDetails: {},
      isOpen: false,
      isOpenRes: false,
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    // this.props.dispatch(userActions.clearDataEVM());
    // this.props.dispatch(userActions.clearData());
    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(
      userActions.getCustomImportList(resData2)
    );
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,
        errorsUser: {},
        fieldsUser: {},
        isOpen: false
        // isOpen: true
      };
    } else {
      return {
        ...nextProps,

      };
    }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} });
  };

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    // console.log(name, value);
    this.setState({ fieldsUser, errorsUser });
  };
  handleInputSearchChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log('name, value', name, value);
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    this.setState({ currentPage: 1 })
    const resData = {
      isBalance: this.state.isBalance,
      keyWord: value ? value : "",
      pageNo: 1,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: Number(this.state.pageSize),
    };

    // console.log("this.props.match.params.id", this.props.match.params.id)
    this.props.dispatch(userActions.getCustomImportList(resData));

  };





  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {


      let { users } = this.props;
      const {
        passphraseValidation
      } = users;

      const filterData = Array.isArray(passphraseValidation)
        ? passphraseValidation.filter(ele => ele?.valid === true).map(ele => ele?.passPhrase)
        : [];

      const resData = {
        incompleteMnemonic: filterData,
      };
      const pageNation = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };

      this.props.dispatch(userActions.customImportSave(resData, pageNation));
    }
  };

  submitHandlePopup = () => {
    if (this.validationCheck()) {
      const { passPhrase } = this.state.fieldsUser;

      // Ensure passPhrase is valid and transform it into an array
      if (!passPhrase || typeof passPhrase !== "string") {
        console.error("Invalid passPhrase");
        return;
      }

      const mnemonicArray = passPhrase
        .split(",")
        .map((mnemonic) => mnemonic.trim())
        .filter((mnemonic) => mnemonic.length > 0); // Remove empty strings

      console.log('mnemonicArraymnemonicArraymnemonicArraymnemonicArraymnemonicArray', mnemonicArray);

      const resData = {
        incompleteMnemonic: mnemonicArray
      }

      this.props.dispatch(userActions.passphraseValidation(resData));
      this.setState({ isOpen: true })
    }
  };


  closeModal = () => {
    this.setState({ isOpen: false, isOpenRes: false, fieldsUser: {} })
  }

  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["passPhrase"] || fieldsUser["passPhrase"] == "") {
      errorsUser["passPhrase"] = `PassPhrase can't be empty.`;
      valid = false;
    }
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const resData2 = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getCustomImportList(resData2)
      );
    });
  };

  renderPagination = () => {
    const { getCustomImportList } = this.props.users;
    const { total } = getCustomImportList
      ? getCustomImportList
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };
  navigatePhraseDetails = (data) => {
    console.log('datadatadatadatadatadatadatadata', data);
    this.setState({ isOpenRes: true, fieldsDetails: data })
  };

  fetchPassPhrase = (id) => {
    this.props.dispatch(
      userActions.fetchPassPhrase(id, {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      })
    );
  }


  deleteValidPassPhrases = (data) => {
    const dataRes = {
      keyWord: "",
      pageNo: this.state.currentPage,
      sortBy: "createdAt",
      sortOrder: "desc",
      size: this.state.pageSize,
    };

    const obj = { id: data.id };

    confirmAlert({
      title: 'Confirm to Delete Pass Phrase?',
      message: `Are you sure you want to delete the pass phrase?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.ValidPassPhraseDelete(obj, dataRes))
        },
        {
          label: 'No'
        }
      ]
    });
  };


  handlePageChange1 = () => {
    if (this.validation()) {
      const pageNumber = this.state.fieldsUser?.['pageNo'] ? Number(this.state.fieldsUser['pageNo']) : null;
      const { getCustomImportList } = this.props.users;
      const { total } = getCustomImportList || {};
      const { currentPage, pageSize } = this.state;
      const totalPages = total ? Math.ceil(total / pageSize) : 0;



      console.log('Total pages:', totalPages);

      if (totalPages <= 1) return null;

      let pages = [];
      const startPage = Math.max(1, pageNumber - 2);
      const endPage = Math.min(totalPages, pageNumber + 2);

      if (startPage > 1) {
        pages.push(
          <span key="first" className="px-4 py-2 mx-1 rounded">
            1
          </span>
        );
        if (startPage > 2) {
          pages.push(
            <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => this.handlePageChange(i)}
            className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
              }`}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(
            <span key="dots-next" className="px-2 py-2 mx-1 rounded">...</span>
          );
        }
        pages.push(
          <button
            key={totalPages}
            onClick={() => this.handlePageChange(totalPages)}
            className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
          >
            {totalPages}
          </button>
        );
      }

      // const pageNumber = this.state && this.state.fieldsUser && this.state.fieldsUser['pageNo']
      pages.push(
        this.handlePageChange(pageNumber)
      )
      return <div className="flex justify-center mt-4">{pages}</div>;
    }
  };
  validation = () => {

    console.log("hello validation");

    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;

    const { getCustomImportList } = this.props.users;
    const { total } = getCustomImportList
      ? getCustomImportList
      : {};

    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    //User Name
    if (!fieldsUser["pageNo"]) {
      formIsValid = false;
      errorsUser["pageNo"] = "Page number cannot be empty.";
    }
    if (fieldsUser["pageNo"] < 0) {
      formIsValid = false;
      errorsUser["pageNo"] = "Please enter positive number.";
    }
    if (fieldsUser["pageNo"] > totalPages) {
      formIsValid = false;
      errorsUser["pageNo"] = "You show only page last number data.";
    }

    this.setState({ errorsUser: errorsUser });
    return formIsValid;

  };



  render() {
    let { users } = this.props;
    const { passPhrase, passPhraseSerch, index } = this.state.fieldsUser;
    const {
      loading,
      validBtcPassPhrases,
      getCustomImportList,
      passphraseValidation
    } = users;


    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      alertActions.success("Copied successfully")
    };
    console.log('getCustomImportListgetCustomImportList', getCustomImportList);


    const { total } = getCustomImportList || {};
    const { pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    const { isOpen, isOpenRes, fieldsDetails } = this.state

    console.log('fieldsDetailsfieldsDetailsfieldsDetailsfieldsDetailsfieldsDetails', fieldsDetails);

    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">
                  <div className="flex flex-col justify-center place-content-center">
                    <div className=" w-[35rem] mx-auto ">
                      <label className="text-[#CB9731]">PassPhrase</label>
                      <div className="my-2">
                        <input
                          type="text"
                          id="passPhrase"
                          name="passPhrase"
                          value={passPhrase ?? ""}
                          onChange={this.handleInputChange}
                          className="w-full p-2 border rounded"
                          placeholder="PassPhrase"
                        />
                        {this.state.errorsUser["passPhrase"] ? (
                          <div className="text-xs italic text-red-500 invalid-feedback">
                            {this.state.errorsUser["passPhrase"]}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <button
                      className="px-5 py-1 bg-[#CB9731] rounded-md my-2 mx-auto"
                      onClick={() => this.submitHandlePopup()}
                    >
                      Submit
                    </button>
                  </div>

                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">
                      {/* <div className='' > */}

                      <div className=" w-[35rem] mx-auto ">
                        <label className="text-[#CB9731]">Search</label>
                        <div className="my-2">
                          <input
                            type="text"
                            id="passPhraseSerch"
                            name="passPhraseSerch"
                            value={passPhraseSerch ?? ""}
                            onChange={this.handleInputSearchChange}
                            className="w-full p-2 border rounded"
                            placeholder="Enter Here"
                          />
                          {this.state.errorsUser["passPhraseSerch"] ? (
                            <div className="text-xs italic text-red-500 invalid-feedback">
                              {this.state.errorsUser["passPhraseSerch"]}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* </div> */}
                      <div className="text-xl font-bold text-center text-white">
                        {validBtcPassPhrases?.validCount ?? null}
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      isCronTab
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      passphrases
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getCustomImportList &&
                                    getCustomImportList.list &&
                                    getCustomImportList.list.length >
                                    0
                                    ? getCustomImportList.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.isCronTab
                                              ? element.isCronTab
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.createdAt
                                              ? moment(
                                                element.createdAt
                                              ).format("DD-MM-YYYY HH:mm")
                                              : null}
                                          </td>
                                          <td className="px-3 py-3 flex items-center gap-3">
                                            <span>
                                              <MdOutlineContentCopy onClick={() => copyToClipboard(element.passPhrase)} className="cursor-pointer " />
                                            </span>
                                            <span>
                                              {element && element.passPhrase
                                                ? element.passPhrase
                                                : "-"}
                                            </span>
                                          </td>
                                          {/* <td className="px-3 py-3 whitespace-nowrap">
                                            <button
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                            ></button>
                                            Details
                                          </td> */}
                                          <td className="gap-2 px-3 py-3 whitespace-nowrap">
                                            <span
                                              onClick={() =>
                                                this.fetchPassPhrase(
                                                  element._id
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2"
                                            >
                                              Fetch Balance
                                            </span>
                                            <span
                                              onClick={() =>
                                                this.navigatePhraseDetails(
                                                  element
                                                )
                                              }
                                              className="bg-[#20BFA9] hover:bg-[#B5862B] px-4 py-2 rounded text-white cursor-pointer mr-2"
                                            >
                                              More Details
                                            </span>
                                            {/* <span
                                              onClick={() => this.deleteValidPassPhrases(element)}
                                              className="bg-[#bf2020] hover:bg-[#ca5252ab] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Delete
                                            </span> */}
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              <div className="w-full mx-auto flex justify-center items-center space-x-4 pb-6">
                                <div>
                                  {this.renderPagination()}
                                </div>
                                {totalPages > 1
                                  ? <>
                                    <input className="py-2 px-2 w-16 rounded-md mt-4" type="number" placeholder="P.No." onChange={this.handleInputChange} name="pageNo" id="pageNo" value={this.state.fieldsUser && this.state.fieldsUser['pageNo'] ? this.state.fieldsUser['pageNo'] : ''} />
                                    <button className="py-2 mt-4 bg-white text-center rounded-md text-black w-20" onClick={() => this.handlePageChange1()}>Submit</button>
                                  </>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </section>

                  {isOpen && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                      <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full mx-4 sm:mx-auto">
                        <button
                          onClick={this.closeModal}
                          className="text-white text-right mb-4"
                        >
                          Close
                        </button>
                        <div className="max-w-full max-h-full overflow-auto">
                          <div className="inline-block w-full h-full">
                            <table className="min-w-full bg-[#24243C] rounded-lg">
                              <thead className="text-white text-center uppercase">
                                <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                  <th className="px-3 py-3 whitespace-nowrap">#</th>
                                  <th className="px-3 py-3 whitespace-nowrap capitalize">
                                    PassPhrase
                                  </th>
                                  <th className="px-3 py-3 whitespace-nowrap capitalize">
                                    Status
                                  </th>
                                </tr>
                              </thead>
                              {passphraseValidation?.length > 0 ? (
                                <tbody>
                                  {passphraseValidation.map((ele, index) => (
                                    <tr
                                      key={index}
                                      className="rounded-lg text-xs text-center text-[#837BB0]"
                                    >
                                      <td className="px-3 py-3 whitespace-nowrap">
                                        {index + 1}
                                      </td>
                                      <td className="px-3 flex gap-2 py-3 whitespace-nowrap">
                                        {ele?.passPhrase ?? null}
                                      </td>
                                      {ele?.valid ?
                                        <td className="px-3 text-green-600  py-3 whitespace-nowrap">
                                          Valid
                                        </td>
                                        :
                                        <td className="px-3 text-red-700 py-3 whitespace-nowrap">
                                          Invalid
                                        </td>}
                                    </tr>
                                  ))}
                                </tbody>
                              ) : null}
                            </table>
                          </div>
                          <div className="text-center ">
                            <button
                              className="px-5 py-1 bg-[#CB9731] text-center rounded-md my-2 mx-auto"
                              onClick={() => this.submitHandle()}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isOpenRes && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                      <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full">
                        <button onClick={this.closeModal} className="text-white text-right mb-4">
                          Close
                        </button>
                        <div className="max-w-full overflow-auto">
                          <div className="inline-block min-w-full">
                            <table className="min-w-full bg-[#24243C] rounded-lg">
                              <thead className="text-white text-center uppercase">
                                <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                  <th className="px-3 py-3 whitespace-nowrap">Type</th>
                                  <th className="px-3 py-3 whitespace-nowrap capitalize">Address</th>
                                  <th className="px-3 py-3 whitespace-nowrap capitalize">Balance</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">BNB</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.ethAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.bnbBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">ETH</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.ethAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.ethBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">SEG-WIT</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.segWitAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.segWitBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">LEGACY</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.legacyAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.legacyBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">NATIVE SEG-WIT</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.nativeSegWitAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.nativeSegWitBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">SOLANA</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.solanaAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.solanaBalance ?? 0}</td>
                                </tr>
                                <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                  <td className="px-3 py-3 whitespace-nowrap">Doge</td>
                                  <td className="px-3 flex gap-2 py-3 whitespace-nowrap">{fieldsDetails?.dogeAddress ?? '-'}</td>
                                  <td className="px-3 py-3 whitespace-nowrap">{fieldsDetails?.dogeBalance ?? 0}</td>
                                </tr>
                              </tbody>
                            </table>
                            {/* Add pagination if needed */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}



                </div>
              </div>
            </div>
          </section >
        </div >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(CustomImport);
