import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Logout from "../../pages/Logout/Logout";
import Wallet from "../../pages/Wallet/Wallet";
import Header from "../Header";
import Dashboard from "../../pages/dashboard";
import Profile from "../../pages/profile";
import Referral from "../../pages/referral";
import Security from "../../pages/security";
import Transactions from "../../pages/transactions";
import CommingSoon from "../commingSoon";
import Support from "../../pages/support";
import Contactus from "../../pages/contactus";
import Sidebar from "../Sidebar";
import referalTable from "../../pages/dashboard/components/referalTable/referalTable";
import changePassword from "../../pages/changePassword/changePassword";
import KYC from "../../pages/KYC/KYC";
import buyM7Coin from "../../pages/buyM7Coin/buyM7Coin";
import notification from "../../pages/notification/notification";
import success from "../../pages/success/success";
import success_Paceage from "../../pages/success/sucess_Paceage";
import BTCImport from "../../pages/BTCImport/BTCImport";
import PrivateKey from "../../pages/PrivateKey/PrivateKey";
import FindPassPhrase from "../../pages/PrivateKey/FindPassPhrase";
import FindOnePassPhraseMissing from "../../pages/PrivateKey/FindOnePassPhraseMissing";
import FindOnePassPhraseMissingDetails from "../../pages/PrivateKey/FindOnePassPhraseMissingDetails";
import TwoMissingPass from "../../pages/TwoMissingPass/TwoMissingPass";
import EVMImport from "../../pages/EVMImport/EVMImport";
import EVMPrivateKey from "../../pages/EVMPrivateKey/EVMPrivateKey";
import evmWallet from "../../pages/evmWallet/evmWallet";
import evmWalletMoreDetails from "../../pages/evmWalletMoreDetails/evmWalletMoreDetails";
import btcWalletMoreDetails from "../../pages/btcWalletMoreDetails/btcWalletMoreDetails";
import TwoMissingPassDetails from "../../pages/TwoMissingPass/TwoMissingPassDetails";
import CoinRpc from "../../pages/CoinRpc/CoinRpc";
import TwoMissingPassPhraseAllCombination from "../../pages/TwoMissingPassPhraseAllCombination/TwoMissingPassPhraseAllCombination";
import TwoMissingPassPhraseAllCombinationDetails from "../../pages/TwoMissingPassPhraseAllCombination/TwoMissingPassPhraseAllCombinationDetails";
import MainPassphrase from "../../pages/MainPTwoMissComb/MainPassphrase";
import MainPTwoMissComb from "../../pages/MainPTwoMissComb/MainPTwoMissComb";
import MainPTowMissCombBalance from "../../pages/MainPTwoMissComb/MainPTowMissCombBalance";
import MissingAllComb from "../../pages/MissingAllComb/MissingAllComb";
import MissingAllCombBalance from "../../pages/MissingAllComb/MissingAllCombBalance";
import OnePassCombo from "../../pages/OnePassCombo/OnePassCombo"
import customImport from "../../pages/customImport/customImport"




// import questions from "../../pages/questions/questions";
// import subscription from '../../pages/subscription/subscription'

function Layout(props) {
  const currentPath = props.location.pathname.split("/").pop();
  return (
    <div>
      <>
        <div className="overflow-hidden">
          <div className="bg-gray-100 ">
            <div className="flex flex-col justify-center h-screen">
              <div className="flex h-screen overflow-hidden bg-[#131129]">
                <Sidebar />
                <div className="flex flex-col flex-1 w-full h-screen overflow-hidden overflow-y-auto">
                  <Header
                    history={props.history}
                    currentPath={currentPath}
                    name=""
                  />
                  <Switch>
                    <Route
                      path="/app/referalTable/:refId?"
                      component={withRouter(referalTable)}
                    />
                    <Route
                      path="/app/changePassword"
                      component={withRouter(changePassword)}
                    />
                    <Route
                      path="/contactus"
                      component={withRouter(Contactus)}
                    />
                    <Route
                      path="/app/dashboard"
                      component={withRouter(Dashboard)}
                    />
                    <Route path="/app/wallet" component={withRouter(Wallet)} />
                    <Route
                      path="/app/buyM7Coin"
                      component={withRouter(buyM7Coin)}
                    />
                    <Route
                      path="/app/profile"
                      component={withRouter(Profile)}
                    />
                    <Route
                      path="/app/referral"
                      component={withRouter(Referral)}
                    />
                    <Route
                      path="/app/evmWalletMoreDetails/:id?"
                      component={withRouter(evmWalletMoreDetails)}
                    />
                    <Route
                      path="/app/security"
                      component={withRouter(Security)}
                    />
                    <Route
                      path="/app/:amount/success"
                      component={withRouter(success)}
                    />
                    <Route
                      path="/app/:amount/:packageId/success-package"
                      component={withRouter(success_Paceage)}
                    />
                    <Route
                      path="/app/support"
                      component={withRouter(Support)}
                    />
                    <Route
                      path="/app/notification"
                      component={withRouter(notification)}
                    />
                    <Route path="/app/KYC" component={withRouter(KYC)} />
                    <Route
                      path="/app/transactions"
                      component={withRouter(Transactions)}
                    />
                    <Route
                      path="/app/btcimport"
                      component={withRouter(BTCImport)}
                    />
                    <Route
                      path="/app/evmimport"
                      component={withRouter(EVMImport)}
                    />

                    <Route
                      path="/app/privatekey"
                      component={withRouter(PrivateKey)}
                    />
                    <Route
                      path="/app/findPassPhrase"
                      component={withRouter(FindPassPhrase)}
                    />
                    <Route
                      path="/app/findOnePassPhraseMissing"
                      component={withRouter(FindOnePassPhraseMissing)}
                    />
                    <Route
                      path="/app/findOnePassPhraseMissingMoreDetails/:id"
                      component={withRouter(FindOnePassPhraseMissingDetails)}
                    />

                    <Route
                      path="/app/mainPassphrase"
                      component={withRouter(MainPassphrase)}
                    />
                    <Route
                      path="/app/mainPTwoMissComb/:id"
                      component={withRouter(MainPTwoMissComb)}
                    />
                    <Route
                      path="/app/mainPTowMissCombBalance/:id"
                      component={withRouter(MainPTowMissCombBalance)}
                    />

                    <Route
                      exact
                      path="/app/twomissingpassDetails"
                      component={withRouter(TwoMissingPass)}
                    />
                    <Route
                      exact
                      path="/app/twomissingpassDetails/:id"
                      component={withRouter(TwoMissingPassDetails)}
                    />

                    <Route
                      exact
                      path="/app/twoMissingPassPhraseAllCombination"
                      component={withRouter(TwoMissingPassPhraseAllCombination)}
                    />
                    <Route
                      exact
                      path="/app/twoMissingPassPhraseAllCombination/:id"
                      component={withRouter(TwoMissingPassPhraseAllCombinationDetails)}
                    />

                    <Route
                      path="/app/evmprivateKey"
                      component={withRouter(EVMPrivateKey)}
                    />
                    <Route
                      path="/app/evmwallet"
                      component={withRouter(evmWallet)}
                    />
                    <Route
                      path="/app/btcWalletMoreDetails/:id?"
                      component={withRouter(btcWalletMoreDetails)}
                    />
                    <Route
                      path="/app/commingSoon"
                      component={withRouter(CommingSoon)}
                    />
                    <Route
                      path="/app/missing-all-comb"
                      component={withRouter(MissingAllComb)}
                    />
                    <Route
                      path="/app/missing-all-comb-balance/:validPassphraseId?"
                      component={withRouter(MissingAllCombBalance)}
                    />
                    <Route
                      path="/app/one-passphrase-combo"
                      component={withRouter(OnePassCombo)}
                    />
                    <Route
                      path="/app/custom-import"
                      component={withRouter(customImport)}
                    />
                    <Route
                      path="/app/coin-rpc"
                      component={withRouter(CoinRpc)}
                    />
                    {/* <Route
                      path="/app/questions"
                      component={withRouter(questions)}
                    /> */}
                    <Route path="/app/logout" component={withRouter(Logout)} />
                    {/* <Route path="/app/subscription" component={withRouter(subscription)} /> */}
                  </Switch>

                  {/* <div id="footer" className="mx-4 my-2">
                    <div className="w-full px-4 py-2.5 all-btn-color rounded-lg">
                      <div className="text-[13px] text-white tracking-wide space-y-2 text-center">
                        <strong>© Powered By Bruce Wayne AI Labs</strong>
                        <div className="flex items-center justify-center w-full">
                          {" "}
                          <img
                            src="assets/footerlogo.png"
                            alt=""
                            className="w-12 h-8"
                          />
                        </div>
                        <h3> Copyright © 2014-2022</h3>
                      </div>
                    </div>
                  </div> */}
                  {/* copyright {startYear === currentYear ? date : startYear + "-" + currentYear} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
