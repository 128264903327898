import {
  userConstants
} from '../_constants';

export function users(state = {}, action) {
  console.log('actionactionactionactionactionaction', action.users);

  switch (action.type) {

    case userConstants.CHANGE_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CHANGE_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        changeEmail: action.users.changeEmail.data,
        otpSentUpdatePass: true
      };
    case userConstants.CHANGE_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        userPromoPackage: action.users.getUserPromoPackage,
      };
    case userConstants.GET_USER_PROMO_PACKAGE_BY_USER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_ALL_LEVEL_MINING_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_LEVEL_MINING_SUCCESS:
      return {
        ...state,
        getAllLevelMining: action.users.getAllLevelMining,
        txTotal: action.users.getAllLevelMining.total,

      };
    case userConstants.GET_ALL_LEVEL_MINING_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case userConstants.GET_LEVEL_BY_ID_USER_TX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LEVEL_BY_ID_USER_TX_SUCCESS:
      return {
        ...state,
        loading: false,
        getLevelByIdUserTx: action.users.getLevelByIdUserTx.list,
        getLevelByIdUserTxTotal: action.users.getLevelByIdUserTx.total,

      };
    case userConstants.GET_LEVEL_BY_ID_USER_TX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        getLevelByIdUserPackage: action.users.getLevelByIdUserPackage.list,
        getLevelByIdUserPackageTotal: action.users.getLevelByIdUserPackage.total,

      };
    case userConstants.GET_LEVEL_BY_ID_USER_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_LEVEL_BY_ID_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_LEVEL_BY_ID_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        getLevelByIdUser: action.users.getLevelByIdUser.list,
        txTotal: action.users.getLevelByIdUser.total,

      };
    case userConstants.GET_LEVEL_BY_ID_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_ALL_LEVEL_WELCOME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_LEVEL_WELCOME_SUCCESS:
      return {
        ...state,
        getAllLevelWelcome: action.users.getAllLevelWelcome,
        txTotal: action.users.getAllLevelWelcome.total,

      };
    case userConstants.GET_ALL_LEVEL_WELCOME_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case userConstants.GET_BPT_DETAIS_REQUEST:
      return {
        ...state,
        loading: false
      };
    case userConstants.GET_BPT_DETAIS_SUCCESS:
      return {
        ...state,
        getBptDetailsForUser: action.users.getBptDetailsForUser.data,
        // txTotal: action.users.getAllLevelWelcome.total,

      };
    case userConstants.GET_BPT_DETAIS_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case userConstants.CREATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case userConstants.CREATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_QUESTION_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_QUESTION_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        questionItam: action.users.getQuestionList.data
      };
    case userConstants.GET_QUESTION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.VALID_PASS_PHRASE_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALID_PASS_PHRASE_DELETE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false
      };
    case userConstants.VALID_PASS_PHRASE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_USER_ANSER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_ANSER_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        answerItam: action.users.getUserAnswerList
      };
    case userConstants.GET_USER_ANSER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.VALIDATE_EVM_PRIVATE_KEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALIDATE_EVM_PRIVATE_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhrases: true,
        saveEVMPrivateKey: action.users.saveEVMPrivateKey
      };
    case userConstants.VALIDATE_EVM_PRIVATE_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_PRIVATE_KEY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_PRIVATE_KEY_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhrases: true,
        savePrivateKey: action.users.savePrivateKey
      };
    case userConstants.SAVE_PRIVATE_KEY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhrases: true,
        savePassphrases: action.users.savePassphrases
      };
    case userConstants.SAVE_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_PASS_PHRASES_EVM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_PASS_PHRASES_EVM_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhrases: true,
        savePassphrasesEvm: action.users.savePassphrasesEvm
      };
    case userConstants.SAVE_PASS_PHRASES_EVM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.VALIDATE_EVM_PRIVATE_KYE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALIDATE_EVM_PRIVATE_KYE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        validateEVMPrivateKey: action.users.validateEVMPrivateKey
      };
    case userConstants.VALIDATE_EVM_PRIVATE_KYE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.VALIDATE_PRIVATE_KYE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALIDATE_PRIVATE_KYE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        validatePrivateKey: action.users.validatePrivateKey
      };
    case userConstants.VALIDATE_PRIVATE_KYE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        validateEVMPassphrases: action.users.validateEVMPassphrases
      };
    case userConstants.VALIDATE_EVM_PASS_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.VALIDATE_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.VALIDATE_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        validatePassphrases: action.users.validatePassphrases
      };
    case userConstants.VALIDATE_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_ANSWERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_ANSWERS_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // questionItam: action.users.getQuestionList.data
      };
    case userConstants.ADD_ANSWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_SECURITY_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_SECURITY_UPDATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        // questionItam: action.users.getQuestionList.data
      };
    case userConstants.USER_SECURITY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_IMAGE_BPT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_IMAGE_BPT_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        loading: false,
      };
    case userConstants.ADD_IMAGE_BPT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_COIN_CMC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_COIN_CMC_SUCCESS:
      return {
        ...state,
        loading: false,
        cmcCoinItem: action.users.cmcCoinData.list,
        cmcCoinTotal: action.users.cmcCoinData.total,
      };
    case userConstants.GET_COIN_CMC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_LIST_BY_EMAIL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_LIST_BY_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        userListByEmailItems: action.user.getUserListByEmail.data,
      };
    case userConstants.GET_USER_LIST_BY_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.WITHDRAW_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.WITHDRAW_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: true,
      };
    case userConstants.WITHDRAW_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CLAIM_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CLAIM_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: true,
      };
    case userConstants.CLAIM_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_USER_PROMO_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_USER_PROMO_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        buyPromoPackage: true,
      };
    case userConstants.CREATE_USER_PROMO_PACKAGE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.SAVE_DFT_WALLET_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_DFT_WALLET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: true,
        // userPackages: action.users.getUserPackages,
      };
    case userConstants.SAVE_DFT_WALLET_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_DFT_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_DFT_WALLET_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        loading: false,
        getDftWalletItems: action.users.getDftWallet,
      };
    case userConstants.GET_DFT_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_BY_REFCODE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_BY_REFCODE_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        addUserSuccess: false,
        loading: false,
        userByRefCode: action.user.getUserByRefCode,
      };
    case userConstants.GET_USER_BY_REFCODE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PACKAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        packageList: action.users.getPackageList.list,
      };
    case userConstants.GET_TICKET_BY_ID_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        ticketbyList: action.users.ticketbyList,
      };
    case userConstants.GET_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CMC_COIN_BY_TICKER_NAME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CMC_COIN_BY_TICKER_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        CoinByTickerName: action.users.getCmcCoinByTickerName,
      };
    case userConstants.CMC_COIN_BY_TICKER_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.PROFILE_PI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PROFILE_PI_SUCCESS:
      return {
        ...state,
        loading: false,
        piItem: action.users.getProfileDataPi,
      };
    case userConstants.PROFILE_PI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_M7I_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_M7I_COIN_SUCCESS:
      return {
        ...state,
        loading: false,
        getM7iCoin: action.users.getM7iCoin,
      };
    case userConstants.GET_M7I_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_USER_NAME_VERIFIED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_NAME_VERIFIED_SUCCESS:
      return {
        ...state,
        // isTicketCreated: false,
        // addUserSuccess: false,
        loading: false,
        isVerifyUserName: true,
        getUserNameVerified: action.users.getUserNameVerified,
      };
    case userConstants.GET_USER_NAME_VERIFIED_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.SAVE_SOCIAL_MEDIA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_SOCIAL_MEDIA_SUCCESS:
      return {
        ...state,
        loading: false,
        isDisabledPersonal: true
      };
    case userConstants.SAVE_SOCIAL_MEDIA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.SAVE_USER_PERSONAL_ADDR_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_USER_PERSONAL_ADDR_SUCCESS:
      return {
        ...state,
        loading: false,
        isDisabledPersonal: true,
        isDisabledAddress: true
      };
    case userConstants.SAVE_USER_PERSONAL_ADDR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_REFERAL_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_REFERAL_BY_ID_SUCCESS:
      return {
        ...state,
        // isTicketCreated: false,
        // addUserSuccess: false,
        loading: false,
        referalItems: action.users.getReferalById,
      };
    case userConstants.GET_REFERAL_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_REFERAL_COUNT_AND_SUM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_REFERAL_COUNT_AND_SUM_SUCCESS:
      return {
        ...state,
        // isTicketCreated: false,
        // addUserSuccess: false,
        loading: false,
        referalCountSum: action.users.getReferralCountAndSumById,
      };
    case userConstants.GET_REFERAL_COUNT_AND_SUM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_MAKE_PAYMENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MAKE_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        addUserSuccess: false,
        loading: false,
        paymentList: action.users.getMakePaymentList.list,
        total: action.users.getMakePaymentList.total,
      };
    case userConstants.GET_MAKE_PAYMENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CREATE_MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        addUserSuccess: true,
        loading: false,
        createMakePayment: action.users.createMakePayment

      };
    case userConstants.CREATE_MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_INR_COIN_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_INR_COIN_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getINRCoinId: action.users.getINRCoinId

      };
    case userConstants.GET_INR_COIN_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        allCoinIndexByIndexId: action.users.getAllCoinIndexByIndexIdData

      };
    case userConstants.GET_ALL_COIN_INDEX_BY_INDEX_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ALL_CITY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_CITY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllCity: action.users.getAllCity

      };
    case userConstants.GET_ALL_CITY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ALL_STATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_STATE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllState: action.users.getAllState

      };
    case userConstants.GET_ALL_STATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ALL_COUNTRY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_COUNTRY_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
        getAllCountry: action.users.getAllCountry

      };
    case userConstants.GET_ALL_COUNTRY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_NOTES_IN_EVM_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_NOTES_IN_EVM_ACCOUNT_SUCCESS:
      return {
        ...state,
        sendCoinSuccess: true,
        loading: false,
      };
    case userConstants.ADD_NOTES_IN_EVM_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.ADD_NOTES_IN_BTC_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.ADD_NOTES_IN_BTC_ACCOUNT_SUCCESS:
      return {
        ...state,
        sendCoinSuccess: true,
        loading: false,
      };
    case userConstants.ADD_NOTES_IN_BTC_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CREATE_WALLET_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CREATE_WALLET_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.CREATE_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SELL_INDEX_REQUEST:
      return {
        ...state,
        // loading: true
      };
    case userConstants.SELL_INDEX_SUCCESS:
      return {
        ...state,
        // loading: false,
        sendCoinSuccess: true,
        sellIndexData: action.user.sellIndex

      };
    case userConstants.SELL_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_INDEX_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_INDEX_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        getIndexOrderData: action.users.getIndexOrder

      };
    case userConstants.GET_INDEX_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_COIN_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_COIN_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        getCoinBalanceData: action.users.getCoinBalance

      };
    case userConstants.GET_COIN_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.BUY_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.BUY_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: true,
        // getAllIndexData: action.users.getAllIndex

      };
    case userConstants.BUY_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ALL_INDEX_REQUEST:
      return {
        ...state,
        loading: true,
        sendCoinSuccess: false
      };
    case userConstants.GET_ALL_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getAllIndexData: action.users.getAllIndex

      };
    case userConstants.GET_ALL_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_BTC_ACCOUNT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_BTC_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: false,
        otpSentUpdatePass: false,
        getBTCAccountList: action.users.getBTCAccountList

      };
    case userConstants.GET_BTC_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: false,
        otpSentUpdatePass: false,
        mainPassphraseCombBalanceById: action.users.getMainPTwoMissCombBalanceById

      };
    case userConstants.GET_MAIN_P_TWO_COMB_BALANCE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_MAIN_PASS_PHRASE_COMB_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MAIN_PASS_PHRASE_COMB_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: false,
        otpSentUpdatePass: false,
        mainPassphraseComb: action.users.getMainPTwoMissCombList

      };
    case userConstants.GET_MAIN_PASS_PHRASE_COMB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_MAIN_PASS_PHRASE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MAIN_PASS_PHRASE_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: false,
        savePhraseSuccess: false,
        otpSentUpdatePass: false,
        mainPassphrase: action.users.getMainPassphreaseList

      };
    case userConstants.GET_MAIN_PASS_PHRASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_EVM_ACCOUNT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_EVM_ACCOUNT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: false,
        otpSentUpdatePass: false,
        getEVMAccountList: action.users.getEVMAccountList

      };
    case userConstants.GET_EVM_ACCOUNT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_KYC_DOC_SELFIE_REQUEST:
      return {
        ...state,
        saveKycDocSelfieSuccess: false,
        loading: true
      };
    case userConstants.SAVE_KYC_DOC_SELFIE_SUCCESS:
      return {
        ...state,
        loading: false,
        saveKycDocSelfieSuccess: true,
        saveKycDocSelfieData: action.users.saveKycDocSelfieData

      };
    case userConstants.SAVE_KYC_DOC_SELFIE_FAILURE:
      return {
        ...state,
        loading: false,
        saveKycDocSelfieSuccess: false,
        error: action.error
      };

    case userConstants.SAVE_KYC_DOC_PASSPORT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_KYC_DOC_PASSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        saveKycDocPassportData: action.users.saveKycDocPassportData

      };
    case userConstants.SAVE_KYC_DOC_PASSPORT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_KYC_DOC_PAN_REQUEST:
      return {
        ...state,
        saveKycDocPanSuccess: false,
        loading: true
      };
    case userConstants.SAVE_KYC_DOC_PAN_SUCCESS:
      return {
        ...state,
        loading: false,
        saveKycDocPanSuccess: true,
        saveKycDocPanData: action.users.saveKycDocPanData

      };
    case userConstants.SAVE_KYC_DOC_PAN_FAILURE:
      return {
        ...state,
        loading: false,
        saveKycDocPanSuccess: false,
        error: action.error
      };

    case userConstants.SAVE_KYC_DOC_AADHAR_REQUEST:
      return {
        ...state,
        saveKycDocAadharSuccess: false,
        loading: true
      };
    case userConstants.SAVE_KYC_DOC_AADHAR_SUCCESS:
      return {
        ...state,
        loading: false,
        saveKycDocAadharSuccess: true,
        saveKycDocAadharData: action.users.saveKycDocAadharData

      };
    case userConstants.SAVE_KYC_DOC_AADHAR_FAILURE:
      return {
        ...state,
        loading: false,
        saveKycDocAadharSuccess: false,
        error: action.error
      };

    case userConstants.SAVE_KYC_DOC_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_KYC_DOC_SUCCESS:
      return {
        ...state,
        loading: false,
        saveKycDocData: action.users.saveKycDocData

      };
    case userConstants.SAVE_KYC_DOC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_DOCUMENT_LIST_OBJ_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_DOCUMENT_LIST_OBJ_SUCCESS:
      return {
        ...state,
        loading: false,
        getDocumentListObjData: action.users.getDocumentListObjData

      };
    case userConstants.GET_DOCUMENT_LIST_OBJ_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfileUpdated: true,
        isDisabledPersonal: true,
        saveUserInfoData: action.users.saveUserInfoData

      };
    case userConstants.SAVE_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_STAKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_STAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        isStakeCreated: true,

      };
    case userConstants.SAVE_STAKE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.SAVE_BTST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SAVE_BTST_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,

      };
    case userConstants.SAVE_BTST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_TICKET_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_TICKET_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true
      };
    case userConstants.USER_TICKET_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_USER_INFO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        isOpneState: true,
        getUserInfo: action.users.userInformation
      };
    case userConstants.GET_USER_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        otpSentUpdatePass: false,
        // isProfileUpdated: true,
        updateUserInfo: action.users.updateUserInfo
      };
    case userConstants.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        // isPasswordUpdate: true,
        upadatePassDetails: action.users.userinfo.data,
        otpSentUpdatePass: true
      };
    case userConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_PASSWORD_VALIDATE_OTP_REQUEST:
      return {
        ...state,
        loggingIn: true,
        user: action.user
      };
    case userConstants.UPDATE_PASSWORD_VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        isPasswordUpdate: true,
        // upadatePassOtp: action.user.userinfo.data,
        otpSent: false
      };
    case userConstants.UPDATE_PASSWORD_VALIDATE_OTP_FAILURE:
      return { ...state };


    case userConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        items: action.users.listOfRestaurant.list,
        total: action.users.listOfRestaurant.total
      };
    case userConstants.GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.TOKEN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        userinfotoken: action.users.userinfotoken
      };
    case userConstants.TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.USER_TICKET_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_TICKET_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        addUserSuccess: false,
        loading: false,
        ticketList: action.users.ticketlistData.list,
        ticketTotal: action.users.ticketlistData.total,
      };
    case userConstants.USER_TICKET_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.TX_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.TX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        txList: action.users.txlistData.list,
        txTotal: action.users.txlistData.total,
        getUserNameVerified: {}

      };
    case userConstants.TX_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_BTC_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_BTC_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validBtcPassPhrases: action.users.getValidBtcPassPhrases,

      };
    case userConstants.GET_VALID_BTC_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_BNB_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_BNB_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validBNBPassPhrases: action.users.getValidBNBPassPhrases,

      };
    case userConstants.GET_VALID_BNB_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_ETH_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_ETH_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validETHPassPhrases: action.users.getValidETHPassPhrases,

      };
    case userConstants.GET_VALID_ETH_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validSegWitPassPhrases: action.users.getValidSegWitPassPhrases,

      };
    case userConstants.GET_VALID_SEG_WIT_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_LEGACY_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_LEGACY_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validLegacyPassPhrases: action.users.getValidLegacyPassPhrases,

      };
    case userConstants.GET_VALID_LEGACY_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SAVE_MAIN_PASS_PHRASE_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SAVE_MAIN_PASS_PHRASE_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        savePhraseSuccess: true,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        saveMainPassphrase: action.users.saveMainPassphrase,

      };
    case userConstants.SAVE_MAIN_PASS_PHRASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        validNativeSegWitPassPhrases: action.users.getValidNativeSegWitPassPhrases,

      };
    case userConstants.GET_VALID_NATIVE_SEG_WIT_PASS_PHRASES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        ValidPassPhrasesWithAddress: action.users.getValidPassPhrasesWithAddress,

      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.CUSTOM_IMPORT_SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.CUSTOM_IMPORT_SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true
      };
    case userConstants.CUSTOM_IMPORT_SAVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_COIN_RPC_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.UPDATE_COIN_RPC_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true

      };
    case userConstants.UPDATE_COIN_RPC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.CREATE_COIN_RPC_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.CREATE_COIN_RPC_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true

      };
    case userConstants.CREATE_COIN_RPC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        ValidPassPhrasesWithAddress: action.users.getValidPassPhrasesWithAddressAllCombination,

      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_ALL_COMB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_ADDRESS_BALANCE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_ADDRESS_BALANCE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        addressBalanceById: action.users.getAddressBalanceById,

      };
    case userConstants.GET_ADDRESS_BALANCE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_SUCCESS:
      return {
        ...state,
        // loading: false,
        // withdrawBalanceSuccess: false,
        // addUserSuccess: false,
        // sendBalanceSuccess: false,
        // sendCoinSuccess: false,
        passPhrasesProgressBarDetails: action.users.showPassPhrasesProgressBar,

      };
    case userConstants.SHOW_PASS_PHRASES_PROGRESS_BAR_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };



    case userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_SUCCESS:
      return {
        ...state,
        // loading: false,
        // withdrawBalanceSuccess: false,
        // addUserSuccess: false,
        // sendBalanceSuccess: false,
        // sendCoinSuccess: false,
        passPhrasesProgressBarDetails1: action.users.showAllComPassPhrasesProgressBar,

      };
    case userConstants.SHOW_ALL_COMB_PASS_PHRASES_PROGRESS_BAR_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_SUCCESS:
      return {
        ...state,
        showMissingAllCombBalanceBar: action.users.showMissingAllCombBalanceBar
        ,

      };
    case userConstants.SHOW_MISSING_ALL_COMB_BALANCE_BAR_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case userConstants.SHOW_ONE_PASS_COMBO_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_ONE_PASS_COMBO_BAR_SUCCESS:
      return {
        ...state,
        showMissingAllCombBalanceBar: action.users.showMissingAllCombBalanceBar,

      };
    case userConstants.SHOW_ONE_PASS_COMBO_BAR_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };




    case userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_SUCCESS:
      return {
        ...state,
        // loading: false,
        // withdrawBalanceSuccess: false,
        // addUserSuccess: false,
        // sendBalanceSuccess: false,
        // sendCoinSuccess: false,
        passPhrasesProgressBarDetails3: action.users.showMainPhrasesProgressCombBar,

      };
    case userConstants.SHOW_MAIN_PHRASES_PROGRESS_BAR_COMB_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };

    case userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_SUCCESS:
      return {
        ...state,
        // loading: false,
        // withdrawBalanceSuccess: false,
        // addUserSuccess: false,
        // sendBalanceSuccess: false,
        // sendCoinSuccess: false,
        passPhrasesProgressBarDetails2: action.users.showMainPassPhrasesProgressBar,

      };
    case userConstants.SHOW_MAIN_PASS_PHRASES_PROGRESS_BAR_FAILURE:
      return {
        ...state,
        // loading: false,
        error: action.error
      };


    case userConstants.GET_ONE_PASS_COMBO_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_ONE_PASS_COMBO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getOnePassComboList: action.users.getOnePassComboList,

      };
    case userConstants.GET_ONE_PASS_COMBO_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        addressBalanceByIdAllComb: action.users.getAddressBalanceByIdAllComb,

      };
    case userConstants.GET_ADDRESS_BALANCE_BY_ID_ALL_COMB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getMissingAllCombBalanceById: action.users.getMissingAllCombBalanceById,

      };
    case userConstants.GET_MISSING_ALL_COMB_BALANCE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ADDRESS_DETAILS_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_ADDRESS_DETAILS_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        addressBalanceById: action.users.getAddressBalanceById,

      };
    case userConstants.GET_ADDRESS_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        ValidPassPhrasesWithAddressList: action.users.getValidPassPhrasesWithAddressList,

      };
    case userConstants.GET_VALID_PASS_PHRASES_WITH_ADDRESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case userConstants.GET_CUSTOM_IMPORT_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_CUSTOM_IMPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: false,
        getCustomImportList: action.users.getCustomImportList

      };
    case userConstants.GET_CUSTOM_IMPORT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_COIN_RPC_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_COIN_RPC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getCoinRpcList: action.users.getCoinRpcList,

      };
    case userConstants.GET_COIN_RPC_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        ValidPassPhrasesWithAddressListAllComb: action.users.getValidPassPhrasesAllCombWithAddressList,

      };
    case userConstants.GET_PASS_PHRASES_ALL_COMB_ADDRESS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_MISSING_ALL_COMB_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_MISSING_ALL_COMB_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: true,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getMissingAllCombList: action.users.getMissingAllCombList,

      };
    case userConstants.GET_MISSING_ALL_COMB_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.PASS_PHRASE_VALIDATION_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.PASS_PHRASE_VALIDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        savePhraseSuccess: false,
        passphraseValidation: action.users.passphraseValidation,

      };
    case userConstants.PASS_PHRASE_VALIDATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SHOW_MISSING_ALL_COMB_BAR_REQUEST:
      return {
        ...state,
        // loading: true,
        // withdrawBalanceSuccess: false
      };
    case userConstants.SHOW_MISSING_ALL_COMB_BAR_SUCCESS:
      return {
        ...state,
        loading: false,
        showMissingAllCombBar: action.users.showMissingAllCombBar,

      };
    case userConstants.SHOW_MISSING_ALL_COMB_BAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_TX_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_TX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        allTxList: action.users.getTxList.list,
        allTxTotal: action.users.getTxList.total,
        getUserNameVerified: {}

      };
    case userConstants.GET_TX_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.EVM_TX_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.EVM_TX_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getEVMTxList: action.users.getEVMTxList.list,
        getEVMTxTotal: action.users.getEVMTxList.total,
        getUserNameVerified: {}

      };
    case userConstants.EVM_TX_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.EVM_TX_LIST_BY_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.EVM_TX_LIST_BY_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        sendCoinSuccess: false,
        getTxListByAddresss: action.users.getTxListByAddresss.list,
        getTxByAddresssTotal: action.users.getTxListByAddresss.total,
        addressAccount: action.users.getTxListByAddresss.addressAccount,
        getUserNameVerified: {}
      };
    case userConstants.EVM_TX_LIST_BY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.NOTIFICATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stateSuccess: false,
        notificationList: action.users.notificationData.list,
        notificationTotal: action.users.notificationData.total,
      };
    case userConstants.NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.TX_LIST_OVERFLOW_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.TX_LIST_OVERFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        overflowTxList: action.users.getUserTxListOverFlow.list,
        overflowTxTotal: action.users.getUserTxListOverFlow.total,
        // getUserNameVerified: {}

      };
    case userConstants.TX_LIST_OVERFLOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        bnbAmontFetchByEvmTx: action.users.bnbAmontFetchByEvmTx.data,
        // getUserNameVerified: {}

      };
    case userConstants.BNB_AMOUNT_FETCH_BY_EVM_TX_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.BNB_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.BNB_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        walletList: action.users.bnbAmont.data.walletList,
        bnbAmont: action.users.bnbAmont.data,

        // getUserNameVerified: {}

      };
    case userConstants.BNB_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.BTC_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.BTC_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        addUserSuccess: false,
        sendBalanceSuccess: false,
        btcAmont: action.users.btcAmount,
      };
    case userConstants.BTC_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_USER_WALLET_REQUEST:
      return {
        ...state,
        loading: true,
        withdrawBalanceSuccess: false
      };
    case userConstants.GET_USER_WALLET_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        userWalletData: action.users.getUserWalletByUserId,
      };
    case userConstants.GET_USER_WALLET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.TX_LIST_BY_DATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.TX_LIST_BY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        txList: action.users.txlistDataByDate.list,
        txTotal: action.users.txlistDataByDate.total,
      };
    case userConstants.TX_LIST_BY_DATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_BTST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_BTST_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        getbtstList: action.users.getbtstlistData.data.list,
        getbtstTotal: action.users.getbtstlistData.data.total,
      };
    case userConstants.GET_BTST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_STAKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_STAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        isStakeCreated: false,
        getstaketList: action.users.getSTAKElistData.data.list,
        getstakeTotal: action.users.getSTAKElistData.data.total,
      };
    case userConstants.GET_STAKE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PACKAGE_BTST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PACKAGE_BTST_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        getPackagebtstList: action.users.getPackageBTST.data,

      };
    case userConstants.GET_PACKAGE_BTST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.GET_PACKAGE_STAKE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_PACKAGE_STAKE_SUCCESS:
      return {
        ...state,
        loading: false,
        addUserSuccess: false,
        getPackageSTAKEList: action.users.getPackageSTAKE.data,

      };
    case userConstants.GET_PACKAGE_STAKE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: true,
      };
    case userConstants.SEND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinTXOTPSuccess: true,
      };
    case userConstants.SEND_OTP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        withdrawBalanceSuccess: false,
        sendBalanceSuccess: false,
        otpSentUpdatePass: false,
        isDisabledPersonal: false,
        sendCoinSuccess: false,
        swapCoinSuccess: false,
        sendCoinTXOTPSuccess: false,
        overview: action.users.getUserDetails,
        // settingOverview: action.users.getUserDetails.setting,
        // userOverview: action.users.getUserDetails.user,
        // walletOverview: action.users.getUserDetails.wallet
      };
    case userConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.COIN_SWAP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.COIN_SWAP_SUCCESS:
      return {
        ...state,
        loading: false,
        otpSentUpdatePass: true,
        // coinSwapData: action.users.coinSwap
      };
    case userConstants.COIN_SWAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ALL_COIN_REMOVE_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_COIN_REMOVE_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        allCoinRemove: action.users.getAllCoinsRemoveId
      };
    case userConstants.GET_ALL_COIN_REMOVE_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ALL_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ALL_COIN_SUCCESS:
      return {
        ...state,
        loading: false,
        allCoin: action.users.getAllCoins
      };
    case userConstants.GET_ALL_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SOCIAL_DETAILS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SOCIAL_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        // isDisabledSocialMedia: false,
        // isDisabledPersonal: true,
        getSocialMediaById: action.users.getSocialMediaById
      };
    case userConstants.SOCIAL_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.PRICE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        priceDeta: action.users.getPrice
      };
    case userConstants.PRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SWAP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SWAP_SUCCESS:
      return {
        ...state,
        loading: false,
        swapCoinSuccess: true,
      };
    case userConstants.SWAP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_BALANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        sendBalanceSuccess: true,
      };
    case userConstants.SEND_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.SEND_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SEND_COIN_SUCCESS:
      return {
        ...state,
        loading: false,
        sendCoinSuccess: true,
      };
    case userConstants.SEND_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.FILE_UPLOAD_STATUS_PASSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        passportFilesDetails: action.users.passportFilesDetails
      };
    case userConstants.FILE_UPLOAD_CLEAR_PASSPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        passportFilesDetails: null
      };
    case userConstants.FILE_UPLOAD_STATUS_PASSPORT_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.FILE_UPLOAD_STATUS_PAN_SUCCESS:
      return {
        ...state,
        loading: false,
        panFilesDetails: action.users.panFilesDetails
      };
    case userConstants.FILE_UPLOAD_CLEAR_PAN_SUCCESS:
      return {
        ...state,
        loading: false,
        panFilesDetails: null
      };
    case userConstants.FILE_UPLOAD_STATUS_PAN_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.FILE_UPLOAD_STATUS_ADHAAR_SUCCESS:
      return {
        ...state,
        loading: false,
        adhaarFilesDetails: action.users.adhaarFilesDetails
      };
    case userConstants.FILE_UPLOAD_CLEAR_ADHAAR_SUCCESS:
      return {
        ...state,
        loading: false,
        adhaarFilesDetails: null
      };
    case userConstants.FILE_UPLOAD_STATUS_ADHAAR_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_SUCCESS:
      return {
        ...state,
        loading: false,
        adhaarFilesDetailsBack: action.users.filesDetails
      };
    case userConstants.FILE_UPLOAD_STATUS_ADHAAR_BACK_FAILURE:
      return {
        ...state,
        loading: false
      };
    case userConstants.FILE_UPLOAD_CLEAR_ADHAAR_BACK_SUCCESS:
      return {
        ...state,
        loading: false,
        adhaarFilesDetailsBack: null
      };

    case userConstants.FILE_UPLOAD_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetails: action.users
      };
    case userConstants.FILE_UPLOAD_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetails: null,
        savePassphrases: null,
        validatePassphrases: null,
        savePhrases: false
      };
    case userConstants.DATA_CLEAR_EVM_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetails: null,
        // savePassphrases: null,
        validateEVMPassphrases: null,
        validateEVMPrivateKey: null,
        // savePhrases: false
      };
    case userConstants.FILE_UPLOAD_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      };

    case userConstants.DATA_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        validBtcPassPhrases: null,
        validBNBPassPhrases: null,
        validETHPassPhrases: null,
        validSegWitPassPhrases: null,
        validLegacyPassPhrases: null,
        validNativeSegWitPassPhrases: null,
        filesDetails: null,
        savePassphrases: null,
        validatePassphrases: null,
        validateEVMPassphrases: null,
        savePassphrasesEvm: null,
        saveEVMPrivateKey: null,
        validatePrivateKey: null,
        savePrivateKey: null,
        savePhrases: false
      };

    case userConstants.FILE_UPLOAD_SELFIE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetailsSelfie: action.users.filesDetails,
        isKYCCreated: true,
      };
    case userConstants.FILE_UPLOAD_SELFIE_CLEAR_SUCCESS:
      return {
        ...state,
        loading: false,
        filesDetailsSelfie: null
      };
    case userConstants.FILE_UPLOAD_SELFIE_STATUS_FAILURE:
      return {
        ...state,
        loading: false
      };


    case userConstants.USER_DOC_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_DOC_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        docList: action.users.getDocDetails
      };
    case userConstants.USER_DOC_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_KYC_REQUEST:
      return {
        ...state,
        isKYCCreated: false,
        loading: true
      };
    case userConstants.GET_KYC_SUCCESS:
      return {
        ...state,
        loading: false,
        kycData: action.users.getKYC
      };
    case userConstants.GET_KYC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.USER_KYC_CREATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.USER_KYC_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        isKYCCreated: true,
      };
    case userConstants.USER_KYC_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };




    case userConstants.GETALL_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GETALL_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        listOfNotification: action.users.listOfNotification.list,
        listOfNotificationtotal: action.users.listOfNotification.total
      };
    case userConstants.GETALL_USER_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.UPDATE_USER_NOTIFY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.UPDATE_USER_NOTIFY_SUCCESS:
      return {
        ...state,
        loading: false
      };
    case userConstants.UPDATE_USER_NOTIFY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.STATS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.STATS_SUCCESS:
      return {
        ...state,
        loading: false,
        statics: action.users.statics
      };
    case userConstants.STATS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.RESET_USER_REDUX:
      return {
      };






    case userConstants.SWAP_COIN_AMOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.SWAP_COIN_AMOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        swapCoinAmountSuccess: true
      };
    case userConstants.SWAP_COIN_AMOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };





    case userConstants.GET_USER_WALLET_BY_COIN_NAME_REQUEST:
      return {
        ...state,
        loading: true,
        swapCoinAmountSuccess: false
      };
    case userConstants.GET_USER_WALLET_BY_COIN_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        getUserWalletByCoinNameSuccess: true,
        getUserWalletByCoinNameData: action.users.getUserWalletByCoinNameData

      };
    case userConstants.GET_USER_WALLET_BY_COIN_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.COUNTRY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.COUNTRY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getNotificationListForUser: action.users.getNotificationListForUser
      };
    case userConstants.COUNTRY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.CITY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.CITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        cityList: action.users.getCityDetails
      };
    case userConstants.CITY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.STATES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.STATES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        stateList: action.users.getStateDetails
      };
    case userConstants.STATES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case userConstants.REPLY_TICKET_BY_USER__REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REPLY_TICKET_BY_USER__SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
        // stateList: action.users.getStateDetails
      };
    case userConstants.REPLY_TICKET_BY_USER__FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_ADDRESS_DEPOSIT_TIME_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_ADDRESS_DEPOSIT_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
        addressDepositTime: action.users.getAddressDepositTime
      };
    case userConstants.GET_ADDRESS_DEPOSIT_TIME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.GET_RECIEVE_COIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_RECIEVE_COIN_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
        recieveCoin: action.users.getRecieveCoin
      };
    case userConstants.GET_RECIEVE_COIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // new 
    case userConstants.GET_USER_BY_NEW_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_USER_BY_NEW_SUCCESS:
      return {
        ...state,
        loading: false,
        isTicketCreated: true,
        recieveCoin: action.users.upDateAction
      };
    case userConstants.GET_USER_BY_NEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    // new 
    case userConstants.GET_MESSAGE_CLIENT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_MESSAGE_CLIENT_LIST_SUCCESS:
      return {
        ...state,
        isTicketCreated: false,
        addUserSuccess: false,
        loading: false,
        ticketbyList: action.users.msgListClient,
        // messageTotal: action.users.messageclientlistData.total,
      };
    case userConstants.GET_MESSAGE_CLIENT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    // new
    case userConstants.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_COIN_BY_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        coinName: action.users.coinData
      }
    case userConstants.GET_COIN_BY_NAME_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    // new
    case userConstants.GET_BTC_AMOUNT_BY_ADDRESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.GET_BTC_AMOUNT_BY_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        btcAmountByAddress: action.users.getbtcAmountByAddress
      }
    case userConstants.GET_BTC_AMOUNT_BY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      }


    //new for Two missingPass 
    case userConstants.DELETE_TWOMISSINGPASS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_TWOMISSINGPASS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_TWOMISSINGPASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //new for Two missingPass 
    case userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.REMOVE_MISS_ALL_COM_MULTIPLE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    //new for Two missingPass 
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //new for Two missingPass 
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_COMB_BALANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    //new for Two missingPass 
    case userConstants.DELETE_MAIN_PASSPHRASE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.DELETE_MAIN_PASSPHRASE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case userConstants.PASSPHASE_ALLCOMB_MULTIPLE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    default:
      return state
  }
}