import React, { Component } from "react";
import { userActions, alertActions } from "../../_actions";
import { connect } from "react-redux";
import "jspdf-autotable";
import LoadingOverlay from "react-loading-overlay";
import "./styles.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Papa from "papaparse";
import moment from "moment";
import { MdOutlineContentCopy } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import { IoCloseSharp } from "react-icons/io5";

class CoinRpc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      listSuccess: [],
      errorsUser: {},
      isOpen: false,
      isOpenUpdate: false,
      fieldsUser: {},
      input1: "",
      input2: "",
      currentPage: 1,
      pageSize: 10,
    };
  }

  componentDidMount() {
    const resData2 = {
      keyWord: "",
      pageNo: this.state.currentPage,
      // sortBy: "createdAt",
      // sortOrder: "desc",
      size: this.state.pageSize,
    };
    this.props.dispatch(userActions.getCoinRpcList(resData2));
    window.scrollTo(0, 0);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.users.savePhraseSuccess) {
      return {
        ...nextProps,
        errorsUser: {},
        fieldsUser: {},
        isOpen: false,
        isOpenUpdate: false
      };
    }
    // else {
    //   return {
    //     ...nextProps,
    //     errorsUser: {},
    //     fieldsUser: {},
    //   };
    // }
  }

  handleCloseModal = () => {
    this.props.dispatch(userActions.clearData());
    this.setState({ errorsUser: {}, fieldsUser: {} });
  };

  // handleInputChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // }
  handleInputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    // this.setState({ [name]: value });
    let fieldsUser = this.state.fieldsUser;
    let errorsUser = this.state.errorsUser;
    fieldsUser[name] = value;
    errorsUser[name] = "";
    console.log('11111111111111111111111122222222222222222333333333333333333', name, value);
    this.setState({ fieldsUser, errorsUser });
  };
  submitHandle = () => {
    // console.log('');
    if (this.validationCheck()) {
      const { shortName, fullName, expApiUrl, apiKey } = this.state.fieldsUser;
      const resData = {
        shortName: shortName,
        fullName: fullName,
        expApiUrl: expApiUrl,
        apiKey: apiKey
      };
      const pageNation = {
        keyWord: "",
        pageNo: this.state.currentPage,
        size: this.state.pageSize,
      };

      this.props.dispatch(userActions.createCoinRpc(resData, pageNation));


    }
  };
  validationCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    console.log('fieldsUserfieldsUserfieldsUserfieldsUser', fieldsUser);
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["shortName"] || fieldsUser["shortName"] == "") {
      errorsUser["shortName"] = `shortName can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["fullName"] || fieldsUser["fullName"] == "") {
      errorsUser["fullName"] = `fullName can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["expApiUrl"] || fieldsUser["expApiUrl"] == "") {
      errorsUser["expApiUrl"] = `expApiUrl can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["apiKey"] || fieldsUser["apiKey"] == "") {
      errorsUser["apiKey"] = `apiKey can't be empty.`;
      valid = false;
    }
    console.log('errorsUsererrorsUsererrorsUsererrorsUser', errorsUser);
    this.setState({ errorsUser: errorsUser });
    return valid;
  };
  submitUpdateHandle = () => {
    // console.log('');
    if (this.validationUpdateCheck()) {
      console.log('this.state.fieldsUserthis.state.fieldsUserthis.state.fieldsUser', this.state.fieldsUser);
      const { shortName, fullName, expApiUrl, apiKey, id } = this.state.fieldsUser;
      const resData = {
        id: id,
        shortName: shortName,
        fullName: fullName,
        expApiUrl: expApiUrl,
        apiKey: apiKey
      };
      const pageNation = {
        keyWord: "",
        pageNo: this.state.currentPage,
        size: this.state.pageSize,
      };

      this.props.dispatch(userActions.updateCoinRpc(resData, pageNation));


    }
  };
  validationUpdateCheck = () => {
    let fieldsUser = this.state.fieldsUser;
    console.log('fieldsUserfieldsUserfieldsUserfieldsUser', fieldsUser);
    let errorsUser = {};
    let valid = true;

    if (!fieldsUser["shortName"] || fieldsUser["shortName"] == "") {
      errorsUser["shortName"] = `shortName can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["fullName"] || fieldsUser["fullName"] == "") {
      errorsUser["fullName"] = `fullName can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["expApiUrl"] || fieldsUser["expApiUrl"] == "") {
      errorsUser["expApiUrl"] = `expApiUrl can't be empty.`;
      valid = false;
    }

    if (!fieldsUser["apiKey"] || fieldsUser["apiKey"] == "") {
      errorsUser["apiKey"] = `apiKey can't be empty.`;
      valid = false;
    }
    console.log('errorsUsererrorsUsererrorsUsererrorsUser', errorsUser);
    this.setState({ errorsUser: errorsUser });
    return valid;
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      const resData2 = {
        keyWord: "",
        pageNo: this.state.currentPage,
        sortBy: "createdAt",
        sortOrder: "desc",
        size: this.state.pageSize,
      };
      this.props.dispatch(
        userActions.getCoinRpcList(resData2)
      );
    });
  };

  renderPagination = () => {
    const { getCoinRpcList } = this.props.users;
    const { total } = getCoinRpcList
      ? getCoinRpcList
      : {};
    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    if (totalPages <= 1) return null;

    let pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // Previous Button
    if (currentPage > 1) {
      pages.push(
        <button
          key="prev"
          onClick={() => this.handlePageChange(currentPage - 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Previous
        </button>
      );
    }

    // Page Numbers
    if (startPage > 1) {
      pages.push(
        <span key="first" className="px-4 py-2 mx-1 rounded">
          1
        </span>
      );
      if (startPage > 2) {
        pages.push(
          <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => this.handlePageChange(i)}
          className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
            }`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <span key="dots-next" className="px-2 py-2 mx-1 rounded">
            ...
          </span>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => this.handlePageChange(totalPages)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          {totalPages}
        </button>
      );
    }

    // Next Button
    if (currentPage < totalPages) {
      pages.push(
        <button
          key="next"
          onClick={() => this.handlePageChange(currentPage + 1)}
          className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
        >
          Next
        </button>
      );
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };


  deleteValidPassPhrases = (data) => {
    const dataRes = {
      keyWord: "",
      pageNo: this.state.currentPage,
      size: this.state.pageSize,
    };

    const obj = { id: data.id };

    confirmAlert({
      title: 'Confirm to Delete RPC detail?',
      message: `Are you sure you want to delete the RPC detail?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.props.dispatch(userActions.deleteCoinRpc(obj, dataRes))
        },
        {
          label: 'No'
        }
      ]
    });
  };

  updateStatusHandle = (data, status) => {
    const resData = {
      id: data.id,
      // status: status
    }
    let gamePageRefresh = {
      keyWord: "",
      pageNo: 1,
      size: 10,
    };
    confirmAlert({
      title: status ? `Confirm to Enable ` : "Confirm to Disable",
      message: status
        ? `Are you sure you want to Enable all game?`
        : `Are you sure you want to Disable all game?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            this.props.dispatch(
              userActions.updateCoinRpcStatus(resData, gamePageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
  };


  handlePageChange1 = () => {
    if (this.validation()) {
      const pageNumber = this.state.fieldsUser?.['pageNo'] ? Number(this.state.fieldsUser['pageNo']) : null;
      const { getCoinRpcList } = this.props.users;
      const { total } = getCoinRpcList || {};
      const { currentPage, pageSize } = this.state;
      const totalPages = total ? Math.ceil(total / pageSize) : 0;



      console.log('Total pages:', totalPages);

      if (totalPages <= 1) return null;

      let pages = [];
      const startPage = Math.max(1, pageNumber - 2);
      const endPage = Math.min(totalPages, pageNumber + 2);

      if (startPage > 1) {
        pages.push(
          <span key="first" className="px-4 py-2 mx-1 rounded">
            1
          </span>
        );
        if (startPage > 2) {
          pages.push(
            <span key="dots-prev" className="px-2 py-2 mx-1 rounded">
              ...
            </span>
          );
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <button
            key={i}
            onClick={() => this.handlePageChange(i)}
            className={`px-4 py-2 mx-1 rounded ${currentPage === i ? "bg-[#CB9731]" : "bg-[#2E2E4E]"
              }`}
          >
            {i}
          </button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push(
            <span key="dots-next" className="px-2 py-2 mx-1 rounded">...</span>
          );
        }
        pages.push(
          <button
            key={totalPages}
            onClick={() => this.handlePageChange(totalPages)}
            className="px-4 py-2 mx-1 rounded bg-[#2E2E4E] hover:bg-[#CB9731]"
          >
            {totalPages}
          </button>
        );
      }

      // const pageNumber = this.state && this.state.fieldsUser && this.state.fieldsUser['pageNo']
      pages.push(
        this.handlePageChange(pageNumber)
      )
      return <div className="flex justify-center mt-4">{pages}</div>;
    }
  };
  validation = () => {

    console.log("hello validation");

    let fieldsUser = this.state.fieldsUser;
    let errorsUser = {};
    let formIsValid = true;

    const { getCoinRpcList } = this.props.users;
    console.log('getCoinRpcListgetCoinRpcListgetCoinRpcListgetCoinRpcListgetCoinRpcList', getCoinRpcList);
    const { total } = getCoinRpcList
      ? getCoinRpcList
      : {};

    const { currentPage, pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;

    //User Name
    if (!fieldsUser["pageNo"]) {
      formIsValid = false;
      errorsUser["pageNo"] = "Page number cannot be empty.";
    }
    if (fieldsUser["pageNo"] < 0) {
      formIsValid = false;
      errorsUser["pageNo"] = "Please enter positive number.";
    }
    if (fieldsUser["pageNo"] > totalPages) {
      formIsValid = false;
      errorsUser["pageNo"] = "You show only page last number data.";
    }

    this.setState({ errorsUser: errorsUser });
    return formIsValid;

  };

  navigatePhraseDetails = (data) => {
    this.setState({ isOpen: true, })
    // this.props.history.push(`/app/TwoMissingPassDetailsAddress/${data.id}`);
  };

  closeModal = () => {
    this.setState({ isOpen: false, fieldsDetails: {}, errorsUser: {} })
  }

  openUpdateModal = (data) => {
    this.setState({ isOpenUpdate: true, fieldsUser: data })
    // this.props.history.push(`/app/TwoMissingPassDetailsAddress/${data.id}`);
  };

  closeUpdateModal = () => {
    this.setState({ isOpenUpdate: false, fieldsUser: {}, errorsUser: {} })
  }



  render() {
    let { users } = this.props;
    const { passPhrase, index } = this.state.fieldsUser;
    const {
      loading,
      validBtcPassPhrases,
      getCoinRpcList,
    } = users;


    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text);
      alertActions.success("Copied successfully")
    };


    const { total, list } = getCoinRpcList || {};
    console.log('listlistlistlistlistlistlistlistlistlist');
    const { pageSize } = this.state;
    const totalPages = total ? Math.ceil(total / pageSize) : 0;


    let { isOpen, isOpenUpdate } = this.state
    let { shortName, fullName, expApiUrl, apiKey } = this.state.fieldsUser



    return (
      <>
        <div>
          <LoadingOverlay
            active={loading}
            className="temp001"
            spinner
            text="Please wait..."
          />
        </div>

        <div className="relative z-0 flex-1 overflow-y-auto focus:outline-none bg_page">
          <section>
            <div className="overflow-hidden">
              <div className="overflow-y-auto">
                <div className="p-8 space-y-3 ">


                  <section className="rounded-lg">
                    <div className="max-w-full overflow-auto">

                      {/* </div> */}
                      <div className="text-end">
                        <button
                          onClick={this.navigatePhraseDetails} // Replace with your function
                          className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg font-bold transition duration-300"
                        >
                          Add
                        </button>
                      </div>
                      <div className="inline-block min-w-full">
                        <section className="rounded-lg">
                          <div className="max-w-full overflow-auto">
                            <div className="inline-block min-w-full">
                              <table className="min-w-full bg-[#24243C] rounded-lg">
                                <thead className="text-center text-white uppercase ">
                                  <tr className="text-white bg-[#2E2E4E] text-sm font-semibold text-center uppercase rounded-t-lg">
                                    <th className="px-3 py-3 whitespace-nowrap">
                                      S/n
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      Full Name
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      Short Name
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      API Key
                                    </th>

                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      Exp Api Url
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      createdAt
                                    </th>
                                    <th className="px-3 py-3 capitalize whitespace-nowrap">
                                      action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {getCoinRpcList &&
                                    getCoinRpcList.list &&
                                    getCoinRpcList.list.length >
                                    0
                                    ? getCoinRpcList.list.map(
                                      (element, index) => (
                                        <tr className="rounded-lg text-xs text-center text-[#837BB0]">
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {this.state.offset + index + 1}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.fullName
                                              ? element.fullName
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {element && element.shortName
                                              ? element.shortName
                                              : 0}
                                          </td>
                                          <td className="px-3 py-3  items-center gap-3">
                                            <span>
                                              <MdOutlineContentCopy onClick={() => copyToClipboard(element?.apiKey)} className="cursor-pointer " />
                                            </span>
                                            <span>
                                              {element && element.apiKey
                                                ? element.apiKey
                                                : "-"}
                                            </span>
                                          </td>
                                          <td className="px-3 py-3  items-center gap-3">
                                            <span>
                                              <MdOutlineContentCopy onClick={() => copyToClipboard(element?.expApiUrl)} className="cursor-pointer " />
                                            </span>
                                            <span>
                                              {element && element.expApiUrl
                                                ? element.expApiUrl
                                                : "-"}
                                            </span>
                                          </td>
                                          <td className="px-3 py-3 whitespace-nowrap">
                                            {/* {element && element.createdAt
                                              ? element.createdAt
                                              : "-"} */}
                                            {element && element.createdAt
                                              ? moment(
                                                element.createdAt
                                              ).format("DD-MM-YYYY HH:mm")
                                              : null}
                                          </td>

                                          <td className="gap-2 px-3 py-3 whitespace-nowrap">

                                            <span
                                              onClick={() => this.deleteValidPassPhrases(element)}
                                              className="bg-[#bf2020] hover:bg-[#ca5252ab] px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Delete
                                            </span>
                                            <span
                                              onClick={() => this.openUpdateModal(element)}
                                              className="bg-blue-500 hover:bg-blue-500 px-4 py-2 rounded text-white cursor-pointer"
                                            >
                                              Update
                                            </span>
                                            {element && element.isDisable ?
                                              <span
                                                onClick={() => this.updateStatusHandle(element, false)}
                                                className="bg-red-500 hover:bg-red-500 px-4 py-2 rounded text-white cursor-pointer"
                                              >
                                                Disable
                                              </span>
                                              :
                                              <span
                                                onClick={() => this.updateStatusHandle(element, true)}
                                                className="bg-green-500 hover:bg-green-500 px-4 py-2 rounded text-white cursor-pointer"
                                              >
                                                Enable
                                              </span>
                                            }
                                          </td>
                                        </tr>
                                      )
                                    )
                                    : null}
                                </tbody>
                              </table>
                              <div className="w-full mx-auto flex justify-center items-center space-x-4 pb-6">
                                <div>
                                  {this.renderPagination()}
                                </div>
                                {totalPages > 1
                                  ? <>
                                    <input className="py-2 px-2 w-16 rounded-md mt-4" type="number" placeholder="P.No." onChange={this.handleInputChange} name="pageNo" id="pageNo" value={this.state.fieldsUser && this.state.fieldsUser['pageNo'] ? this.state.fieldsUser['pageNo'] : ''} />
                                    <button className="py-2 mt-4 bg-white text-center rounded-md text-black w-20" onClick={() => this.handlePageChange1()}>Submit</button>
                                  </>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>

                      {isOpen && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                          <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full">
                            {/* Close Button */}

                            <div className="flex justify-between items-center pb-2">
                              <h2 className="text-white text-lg">Add Coin</h2>
                              <div>
                                <button onClick={this.closeModal} className="text-white text-l mb-4">
                                  <IoCloseSharp className="w-7 h-7 bg-white text-black rounded-full p-1 hover:text-white hover:bg-red-700" />
                                </button>
                              </div>
                            </div>

                            {/* Short Name */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Short Name</label>
                              <input
                                type="text"
                                id="shortName"
                                name="shortName"
                                value={shortName}
                                placeholder="Type to short name..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.shortName && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.shortName}</p>
                              )}
                            </div>

                            {/* Full Name */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Full Name</label>
                              <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={fullName}
                                placeholder="Type to full name..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.fullName && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.fullName}</p>
                              )}
                            </div>

                            {/* Exp Api Url */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Exp Api Url</label>
                              <input
                                type="text"
                                id="expApiUrl"
                                name="expApiUrl"
                                value={expApiUrl}
                                placeholder="Type to exp api url..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.expApiUrl && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.expApiUrl}</p>
                              )}
                            </div>

                            {/* API Key */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">API Key</label>
                              <input
                                type="text"
                                id="apiKey"
                                name="apiKey"
                                value={apiKey}
                                placeholder="Type to api key..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.apiKey && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.apiKey}</p>
                              )}
                            </div>

                            {/* Submit Button */}
                            <div className="text-center">
                              <button
                                onClick={this.submitHandle}
                                className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg font-bold transition duration-300"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {isOpenUpdate && (
                        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                          <div className="bg-[#24243C] rounded-lg p-4 max-w-3xl w-full">
                            {/* Close Button */}

                            <div className="flex justify-between items-center pb-2">
                              <h2 className="text-white text-lg">Update Coin</h2>
                              <div>
                                <button onClick={this.closeUpdateModal} className="text-white text-l mb-4">
                                  <IoCloseSharp className="w-7 h-7 bg-white text-black rounded-full p-1 hover:text-white hover:bg-red-700" />
                                </button>
                              </div>
                            </div>

                            {/* Short Name */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Short Name</label>
                              <input
                                type="text"
                                id="shortName"
                                name="shortName"
                                value={shortName}
                                placeholder="Type to short name..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.shortName && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.shortName}</p>
                              )}
                            </div>

                            {/* Full Name */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Full Name</label>
                              <input
                                type="text"
                                id="fullName"
                                name="fullName"
                                value={fullName}
                                placeholder="Type to full name..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.fullName && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.fullName}</p>
                              )}
                            </div>

                            {/* Exp Api Url */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">Exp Api Url</label>
                              <input
                                type="text"
                                id="expApiUrl"
                                name="expApiUrl"
                                value={expApiUrl}
                                placeholder="Type to exp api url..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.expApiUrl && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.expApiUrl}</p>
                              )}
                            </div>

                            {/* API Key */}
                            <div className="mb-4">
                              <label className="block text-white mb-2">API Key</label>
                              <input
                                type="text"
                                id="apiKey"
                                name="apiKey"
                                value={apiKey}
                                placeholder="Type to api key..."
                                className="w-full px-3 py-2 border rounded-lg text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                onChange={this.handleInputChange}
                              />
                              {this.state && this.state.errorsUser && this.state.errorsUser.apiKey && (
                                <p className="text-red-500 text-sm mt-1">{this.state && this.state.errorsUser && this.state.errorsUser.apiKey}</p>
                              )}
                            </div>

                            {/* Submit Button */}
                            <div className="text-center">
                              <button
                                onClick={this.submitUpdateHandle}
                                className="text-white bg-blue-600 hover:bg-blue-700 px-4 py-2 rounded-lg font-bold transition duration-300"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      )}


                    </div>
                  </section>
                </div>
              </div>
            </div >
          </section >
        </div >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  console.log("usersusersusersusersusersusers", users);
  const { overview } = users ? users : {};
  const { setting } = overview ? overview : {};
  return {
    users,
    setting,
  };
}
export default connect(mapStateToProps)(CoinRpc);
